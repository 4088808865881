export const MenuItems = [
    {
        title: '账户资讯',
        path: '/account',
        state: ['RcmForm', 'all'],
        cName: 'dropdown-link',
        iconClass: 'material-symbols-rounded',
        icon: 'settings',
    },
    {
        title: '付款历史',
        path: '/history',
        cName: 'dropdown-link',
        iconClass: 'material-symbols-rounded',
        icon: 'history',
    },
];