import React, { useEffect } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

export default function CheckoutForm({ setErrMsg }) {
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      //console.log("delay to showBtn");
      setShowBtn(true);
    }, 3000);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //console.log(isProcessing, !stripe, !elements);
    //console.log(isProcessing || !stripe || !elements);

    if (!stripe || !elements) {
      console.log("return, no stripe");
      return;
    } else {
      console.log("setIsProcessing true");
      setIsProcessing(true);
    }

    state?.writing && localStorage.setItem("editedWriting", state.writing);
    //setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href.includes("Result")
          ? `${window.location.href.replace("Payment", "")}`
          : `${window.location.href}`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setErrMsg(error.message);
      setMessage(error.message);
      //console.log(error.message);
    } else {
      setErrMsg("An unexpected error occured.");
      setMessage("An unexpected error occured.");
      console.log("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  //`${window.location.href.replace("Payment", "")}`

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {showBtn && (
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="float_btn"
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : "付款"}
          </span>
        </button>
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
