import React from "react";
import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth, useMyWritings } from "./hooks/useContext";
import Footer from "./infoPages/Footer";
import axios from "axios";

const PHONE_REGEX = /^1[3456789]\d{9}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default function Login() {
  const navigate = useNavigate();

  const { setAuth } = useAuth();
  const { setLogIn, logIn, documents } = useMyWritings();

  const errRef = useRef();

  const [userPhone, setUserPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [pwd, setPwd] = useState("");

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(userPhone));
  }, [userPhone]);

  useEffect(() => {
    setErrMsg("");
  }, [userPhone, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(userPhone, pwd);
    const login = async function () {
      const data = {
        name: userPhone,
        password: pwd,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}api/v1/user/login`, data, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.status === "success") {
            //console.log("success", "Logged in successfully!");
            //console.log(res.data);
            //console.log(document.cookie);
            setAuth(true);
            setLogIn(true);
          }
        })
        .catch((err) => {
          if (!err) {
            setErrMsg("No Server Response");
          } else {
            console.log(err.response.data.message);
            setErrMsg(err.response.data.message);
          }
          errRef.current.focus();
        });
    };
    login();
  };

  useEffect(() => {
    if (logIn && documents) {
      documents.length > 0 ? navigate("/myWritings") : navigate("/addFile");
    }
  }, [documents]);

  return (
    <div className="infoP">
      <div className="loginPage">
        <div className="login">
          <h1>登录</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="userPhone">中国手机号*</label>
            <input
              type="phone"
              id="userPhone"
              autoComplete="off"
              onChange={(e) => setUserPhone(e.target.value)}
              value={userPhone}
              required
              aria-invalid={validPhone ? "false" : "true"}
              aria-describedby="phonenote"
              onFocus={() => setPhoneFocus(true)}
              onBlur={() => setPhoneFocus(false)}
            />
            <p
              id="phonenote"
              className={
                phoneFocus && userPhone && !validPhone
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              手机号格式不正确
            </p>

            <label htmlFor="password">密码*</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

            <button style={{ marginTop: "30px" }}>登录</button>
          </form>
          <p>
            <span className="line">
              <Link
                to="/forgetPassword"
                style={{ textDecoration: "underline" }}
              >
                忘记密码{" "}
              </Link>
            </span>
          </p>
          <p>
            还没有帐号？由此
            <span className="line">
              <Link to="/register" style={{ textDecoration: "underline" }}>
                注册
              </Link>
            </span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
