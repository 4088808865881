import React from "react";
import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth, useMyWritings } from "./hooks/useContext";
import Footer from "./infoPages/Footer";
import axios from "axios";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/;

export default function Login() {
  const navigate = useNavigate();

  const { token } = useParams();

  const { setAuth } = useAuth();
  const { setLogIn, logIn, documents } = useMyWritings();

  const errRef = useRef();

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}api/v1/user/resetPassword/${token}`,
        data: {
          password: pwd,
          passwordConfirm: matchPwd,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        console.log("success", "Signed up successfully!");
        //console.log(res.data);
        setAuth({ user: res.data.data.user.name, pwd });
        setLogIn(true);
      }
    } catch (err) {
      //console.log(err.response.data.message);
      alert("error", "Error log in! Try again.");
    }
  };

  useEffect(() => {
    if (logIn && documents) {
      documents.length > 0 ? navigate("/myWritings") : navigate("/addFile");
    }
  }, [documents]);

  return (
    <div className="infoP">
      <div className="loginPage">
        <div className="login">
          <h1>重设密码</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="password">密码*</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
            <p
              id="pwdnote"
              className={
                pwdFocus && pwd && !validPwd ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              6-24位，须包含英文大写、小写及数字
            </p>

            <label htmlFor="confirm_pwd">确认密码*</label>
            <input
              type="password"
              id="confirm_pwd"
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby="confirmnote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
            <p
              id="confirmnote"
              className={
                matchFocus && !validMatch ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              密码不一致
            </p>

            <button style={{ marginTop: "30px" }}>重设密码</button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}
