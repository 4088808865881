import React from "react";
import Logo from "../assets/Logo.svg";
import QRcode from "../assets/QRcode.JPG";

export default function Footer() {
  return (
    <footer
      className="footer"
      style={{ display: "flex", borderTop: "solid 1px #E2E8F0" }}
    >
      <img
        alt="footer-logo"
        style={{ padding: "64px 0", width: "auto" }}
        src={Logo}
      />
      <div style={{ display: "flex", position: "absolute", right: "0" }}>
        <div
          style={{
            textAlign: "right",
            color: "#475569",
            marginTop: "110px",
          }}
        >
          咨询线上客服👉👉
          {/*<br />
          <a style={{ textDecoration: "underline" }} href="mailto:">
            contact@luohuashengwriting.com
          </a>*/}
          <br />
          Copyright 2024 PodsAI
        </div>

        <img
          alt="consultant-QRcode"
          style={{ margin: "64px 80px 64px 32px", width: "100px" }}
          src={QRcode}
        />
      </div>
    </footer>
  );
}
