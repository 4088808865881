import React, { useState, useEffect } from "react";
import Stripe from "./Stripe";
import { useFormCtn, useMyWritings } from "../hooks/useContext";
import { useLocation, useParams } from "react-router-dom";
import Completion from "./Completion";
import CompletionBackup from "./CompletionBackup";
import UseQuota from "./UseQuota";

const COMFIRM_REGEX =
  /^\?payment_[A-Za-z]+=([A-Za-z0-9]+(_[A-Za-z0-9]+)+)&payment_intent_client_secret=([A-Za-z0-9]+(_[A-Za-z0-9]+)+)&redirect_status=[A-Za-z]+$/i;

export default function Payment() {
  const location = useLocation();
  const { docNumber } = useParams();

  const { quota } = useMyWritings();
  const { progress, setDocId, docType } = useFormCtn();
  const [ableToPay, setAbleToPay] = useState();

  useEffect(() => {
    setDocId(docNumber);
    console.log("set doc id");
  }, [docNumber]);

  useEffect(() => {
    progress &&
      setAbleToPay(progress.filter((el) => el === false).length === 0);
  }, [progress]);

  return (
    <>
      {ableToPay ? (
        <>
          {COMFIRM_REGEX.test(location.search) &&
          location.search.slice(-9) === "succeeded" ? (
            <Completion />
          ) : (
            <>
              {location.search.slice(-9) === "succeeded" ? (
                <CompletionBackup />
              ) : (
                <div className="formStyle">
                  {docType === "prp" && quota > 0 ? (
                    <UseQuota />
                  ) : (
                    <Stripe docType={docType} />
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="formStyle">You Have to complete the form</div>
      )}
    </>
  );
}
