import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useResult } from "../hooks/useContext";
import axios from "axios";

export default function GenerateWriting(props) {
  const navigate = useNavigate();

  const { setResult, postOpenaiRating, errMsg, setErrMsg } = useResult();
  const { formCtn, docType, docId, setPrpType } = useFormCtn();

  const postOpenaiRcm = async function () {
    //console.log(formCtn);
    const [candidate, pronoun, program, university] = formCtn[0];
    const [recommender, position, organisation, profession] = formCtn[1];
    const [relationship, recommend, general] = formCtn[2];
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/openai_rcm`,
        data: {
          candidate: candidate,
          pronoun: pronoun,
          program: program,
          university: university,
          recommender: recommender,
          position: position,
          organisation: organisation,
          profession: profession,
          relationship: relationship,
          recommend: recommend,
          general: general,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data);
        let writing = res.data.rcmWriting;
        writing && setResult((current) => [...current, [writing, ""]]);
        postOpenaiRating(writing, "rcm");
      }
    } catch (error) {
      console.error("Error while posting to openai", error);
      setErrMsg("连接失败，请稍后再尝试一次。");
    }
  };

  const postOpenaiSop = async function () {
    //console.log(formCtn);
    const [current_uni, current_major, program, university, words] = formCtn[0];
    const background = formCtn[1];
    const experience = formCtn[2];
    const charaters = formCtn[3];
    const graduate = formCtn[4];
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/openai_sop`,
        data: {
          current_uni: current_uni,
          current_major: current_major,
          program: program,
          university: university,
          words: words,
          background: background,
          experience: experience,
          charaters: charaters,
          graduate: graduate,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data);
        let writing = res.data.sopWriting;
        writing && setResult((current) => [...current, [writing, ""]]);
        postOpenaiRating(writing, "sop");
      }
    } catch (error) {
      console.error("Error while posting to openai", error);
      setErrMsg("连接失败，请稍后再尝试一次。");
    }
  };

  const postOpenaiPrp = async function () {
    //console.log(formCtn);
    const [[type, draft]] = formCtn;
    setPrpType(type);
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/openai_prp`,
        data: {
          draft: draft,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data);
        let writing = res.data.paraphrasedWriting;
        writing && setResult((current) => [...current, [writing, ""]]);
        postOpenaiRating(writing, type);
        props?.quotaUsed && props.quotaUsed();
      }
    } catch (error) {
      console.error("Error while posting to openai", error);
      setErrMsg("连接失败，请稍后再尝试一次。");
    }
  };

  const handleGeneration = async function () {
    setResult([]);
    docType === "rcm" && postOpenaiRcm();
    docType === "sop" && postOpenaiSop();
    docType === "prp" && postOpenaiPrp();
    navigate(`/_${docType}Result/${docId}/0`);
  };

  return (
    <div>
      <button onClick={handleGeneration} className={props?.style}>
        ✨生成文书✨{" "}
      </button>
    </div>
  );
}
