import React, { useEffect, useState } from "react";
import DownloadWriting from "../components/DownloadWriting";
import Paraphrase from "../components/Paraphrase";
import StarRating from "../components/StarRating";
import { useResult, useFormCtn, useMyWritings } from "../hooks/useContext";
import ErrMessage from "../components/ErrMessage";
import { useNavigate, Link, useLocation } from "react-router-dom";
import TabsResult from "../Layout/TabsResult";
import Skeleton from "../components/Skeleton";
//import ResultInstruction from "../components/ResultInstruction";
import PlusPopup from "../components/PlusPopup";
import CompletionPlus from "./CompletionPlus";
import SemiCircleProgress from "../components/SemiCircleProgress";
import ContentEditable from "../components/ContentEditable";
import ErrParaphrase from "../components/ErrParaphrase";
import axios from "axios";

const SCORE_REGEX = /\d/;
const COMFIRM_REGEX =
  /^\?payment_[A-Za-z]+=([A-Za-z0-9]+(_[A-Za-z0-9]+)+)&payment_intent_client_secret=([A-Za-z0-9]+(_[A-Za-z0-9]+)+)&redirect_status=[A-Za-z]+$/i;

export default function Result() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    result,
    version,
    errMsg,
    setErrMsg,
    setResult,
    errMsgPrp,
    setErrMsgPrp,
  } = useResult();
  const { docTitle, docType, docId, formCtn, prpType, setPrpType } =
    useFormCtn();
  const { firstTime, quota, runMyWritingsProvd, setRunMyWritingsProvd } =
    useMyWritings();

  const [writing, setWriting] = useState("");
  const [editedWriting, setEditedWriting] = useState("");
  const [tips, setTips] = useState([]);
  const [detector, setDetector] = useState([]);
  const [renderTips, setRenderTips] = useState(false);
  const [contentEdited, setContentEdited] = useState(false);
  const [words, setWords] = useState();
  const [currentTip, setCurrentTip] = useState(0);
  const [detectorWork, setDetectorWork] = useState(true);

  //const [instructionShow, setInstructionShow] = useState(false);
  const [plusPopupShow, setPlusPopupShow] = useState(false);

  const getWords = () => {
    const wordsDirty = writing.split(" ");
    setWords(wordsDirty.length);
  };

  useEffect(() => {
    //console.log("writing change");
    writing && getWords();
  }, [writing]);

  useEffect(() => {
    if (tips.length >= 4) {
      setRenderTips(true);
    }
  }, [tips]);

  useEffect(() => {
    docType === "prp" && !prpType && setPrpType(prpType);
    //console.log(prpType);
    setRenderTips(false);
    setTips([]);
    setWriting("");
    setDetector([]);
    setDetectorWork(true);

    if (result && result.length > version && result[version].length > 1) {
      setWriting(result[version][0]);
      setDetector(result[version][2]);
      let tipArr = [];
      const tipsCntArr = result[version][1].split("\n\n");
      tipsCntArr.forEach((tipCnt) => {
        let index = tipCnt.search(SCORE_REGEX);
        let score = tipCnt.slice(index, index + 2);
        //console.log(tipCnt.slice(index + 2, index + 4), SCORE_REGEX.test(tipCnt.slice(index + 2, index + 4)))
        if (SCORE_REGEX.test(tipCnt.slice(index + 2, index + 4))) {
          let tip = tipCnt.slice(index + 4);
          tipArr.push([score, tip]);
        } else {
          let tip = tipCnt.slice(index + 2);
          tipArr.push([score, tip]);
        }
      });
      setTips(tipArr);
    }
    getWords();
  }, [result, version]);

  const saveDetectedInfo = async (outcome) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
        data: {
          result: [
            ...result.slice(0, version),
            outcome,
            ...result.slice(Number(version) + 1),
          ],
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data.data.doc.result);
        console.log("detectedInfo saved");
        setResult(res.data.data.doc.result);
        setRunMyWritingsProvd(!runMyWritingsProvd);
      }
    } catch (error) {
      console.error("Error while updating result", error);
    }
  };

  const detectAi = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/aiDetector`,
        data: {
          writing: writing,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data.data);
        const detectedInfo = res.data.data;
        if (
          typeof detectedInfo === "object" &&
          Object.keys(detectedInfo).length > 0
        ) {
          console.log(detectedInfo);
          setDetectorWork(true);
          setDetector(detectedInfo);
          saveDetectedInfo([...result[version], detectedInfo]);
        } else {
          setDetectorWork(false);
          setDetector(["nan", []]);
        }
      }
      console.log(detectAi);
    } catch (error) {
      console.error(error);
      setDetectorWork(false);
      setDetector(["nan", []]);
      console.log(detector);
    }
  };

  useEffect(() => {
    if (result && writing && renderTips && result[version].length === 2) {
      console.log(result[version]);
      console.log("no detector/run zeroGPT");
      detectAi();
    }
  }, [renderTips]);

  const limitMap = new Map([
    ["rcm", 3400],
    ["sop", 10000],
  ]);

  const heightMap = new Map([
    ["rcm", 0.65],
    ["sop", 0.45],
  ]);

  const handleError = () => {
    setErrMsg("");
    navigate("/myWritings");
  };

  useEffect(() => {
    if (
      writing.replaceAll("\n", "").replaceAll(" ", "") ===
      editedWriting.replaceAll("\n", "").replaceAll(" ", "")
    ) {
      setContentEdited(false);
    } else {
      setContentEdited(true);
    }
    //console.log(contentEdited);
    //console.log(writing.replaceAll('\n', "").replaceAll(" ", ""))
    //console.log(editedWriting.replaceAll('\n', "").replaceAll(" ", ""))
  }, [editedWriting]);

  return (
    <div className="result">
      {COMFIRM_REGEX.test(location.search) &&
        location.search.slice(-9) === "succeeded" && (
          <CompletionPlus docType={docType} writing={writing} />
        )}
      {plusPopupShow && (
        <PlusPopup
          handleClose={() => {
            setPlusPopupShow(false);
          }}
          writing={editedWriting}
        />
      )}
      {errMsg && <ErrMessage message={errMsg} handleClose={handleError} />}
      {errMsgPrp && (
        <ErrParaphrase
          docType={docType}
          setErrMsgPrp={setErrMsgPrp}
          editedWriting={editedWriting}
          setEditedWriting={setEditedWriting}
          contentEdited={contentEdited}
        />
      )}

      <div className="editArea">
        <div style={{ display: "flex" }}>
          <h3 className="title" style={{ flex: "auto" }}>
            {docTitle ? docTitle : "未命名文件"}
          </h3>
          {docType !== "prp" && (
            <Link
              style={{
                flex: "none",
                fontSize: "14px",
                marginTop: "2vh",
                color: "#475569",
              }}
              to={`/_${docType}formContent/${docId}`}
            >
              <span className="material-symbols-rounded">history</span>
              {`\u00A0\u00A0`}查看问卷内容
            </Link>
          )}
        </div>

        <TabsResult />

        {writing ? (
          <div>
            <span
              style={{
                color: "#DB5461",
                fontFamily: "PingFangSC-Regular, sans-serif",
                fontWeight: "700",
              }}
            >
              {`\u00A0`}
              <span className="material-symbols-rounded">auto_fix</span>
              {`\u00A0\u00A0`}直接用中文于下方修改或新增内容{" "}
            </span>
            <span className="help" style={{ float: "right" }}>
              英文单词数:{words}字
            </span>

            {detectorWork && detector && detector[1] !== undefined ? (
              <ContentEditable
                writing={writing}
                AIWriting={detector[1]}
                setEditedWriting={setEditedWriting}
              />
            ) : (
              <textarea
                type="text"
                name="writing"
                value={writing}
                onChange={(e) => {}}
                required
                maxLength={
                  docType === "prp" && prpType
                    ? limitMap.get(prpType)
                    : limitMap.get(docType)
                }
                style={
                  docType === "prp" && prpType
                    ? {
                        height: `${Math.ceil(
                          writing.length * heightMap.get(prpType)
                        )}px`,
                      }
                    : {
                        height: `${Math.ceil(
                          writing.length * heightMap.get(docType)
                        )}px`,
                      }
                }
              ></textarea>
            )}

            <DownloadWriting writing={writing} docTitle={docTitle} />
          </div>
        ) : (
          <div className="editArea">
            <div style={{ marginBottom: "30px", color: "#475569" }}>
              传输中，一般生成时间大约2-3分钟，请务必留在此页面并耐心等待...
            </div>
            <Skeleton />
          </div>
        )}
      </div>

      <div className="resultRight">
        {renderTips ? (
          <div className={writing ? "tips" : "offscreen"}>
            {detector && (
              <div>
                <SemiCircleProgress percentage={detector[0]} />

                {typeof Number(detector[0]) === "number" &&
                detector[0] !== "nan" ? (
                  <>
                    {Number(detector[0]) < 20 ? (
                      <div
                        style={{
                          marginTop: "-16px",
                          marginBottom: "2vh",
                          color: "#475569",
                        }}
                      >
                        由AI产生的概率僅为{Math.trunc(detector[0])}
                        %, 是具有独特性的个性化文书👍 👍{" "}
                        <span
                          style={{
                            textDecorationLine: "underline",
                            color: "#DB5461",
                            fontWeight: "600",
                          }}
                        >
                          突出显示
                        </span>
                        的句子被判定为AI生成,
                        可以直接在左侧文本框使用中文或英语修改或添加内容。
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "-16px",
                          marginBottom: "2vh",
                          color: "#475569",
                        }}
                      >
                        文书被检测到为 AI 产生的概率过高,&nbsp;
                        <span
                          style={{
                            textDecorationLine: "underline",
                            color: "#DB5461",
                            fontWeight: "600",
                          }}
                        >
                          突出显示
                        </span>
                        的句子被认定为AI所写,
                        建议直接在左侧框以中文或英语加入更多独特个人信息,
                        以有效降低 AI 概率。
                      </div>
                    )}
                  </>
                ) : (
                  <div style={{ marginTop: "10px", marginBottom: "2vh" }}>
                    AI检测正在努力加载中,请您喝杯茶后回來查看结果。
                  </div>
                )}
              </div>
            )}
            <div>
              <div
                style={{
                  position: "relative",
                  backgroundColor: "rgba(170, 125, 206, 0.12)",
                  padding: "16px 16px 16px 16px",
                  zIndex: "8",
                }}
              >
                <div style={{ height: "22vh", overflowY: "scroll" }}>
                  {currentTip === 0 && (
                    <div className="tipDiv">
                      <div
                        className="tipTitle"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="tipHead">具体和详细</div>
                        <StarRating tipScore={Number(tips[0][0] / 2)} />
                      </div>
                      <div className="tip">{tips[0][1]}</div>
                    </div>
                  )}
                  {currentTip === 1 && (
                    <div className="tipDiv">
                      <div
                        className="tipTitle"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="tipHead">使用专业的用語</div>
                        <StarRating tipScore={Number(tips[1][0] / 2)} />
                      </div>
                      <div className="tip">
                        {tips[1][1]}
                        <div style={{ marginTop: "10px" }}>
                          ＊提醒您：如需提到专业术语，请直接用英语书写
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTip === 2 && (
                    <div className="tipDiv">
                      <div
                        className="tipTitle"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="tipHead">突出相关经验</div>
                        <StarRating tipScore={Number(tips[2][0] / 2)} />
                      </div>
                      <div className="tip">{tips[2][1]}</div>
                    </div>
                  )}
                  {currentTip === 3 && (
                    <div className="tipDiv">
                      <div
                        className="tipTitle"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="tipHead">结构完整且流畅</div>
                        <StarRating tipScore={Number(tips[3][0] / 2)} />
                      </div>
                      <div className="tip">{tips[3][1]}</div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "fit-content",
                    display: "flex",
                    gap: "28px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={() =>
                      setCurrentTip(
                        currentTip % 4 > 0
                          ? (currentTip % 4) - 1
                          : (currentTip % 4) + 4 - 1
                      )
                    }
                  >
                    arrow_circle_left
                  </span>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      gap: "5px",
                      top: "10px",
                    }}
                  >
                    <div
                      className={currentTip === 0 ? "tipCurrent" : "tipB"}
                      onClick={() => setCurrentTip(0)}
                    ></div>
                    <div
                      className={currentTip === 1 ? "tipCurrent" : "tipB"}
                      onClick={() => setCurrentTip(1)}
                    ></div>
                    <div
                      className={currentTip === 2 ? "tipCurrent" : "tipB"}
                      onClick={() => setCurrentTip(2)}
                    ></div>
                    <div
                      className={currentTip === 3 ? "tipCurrent" : "tipB"}
                      onClick={() => setCurrentTip(3)}
                    ></div>
                  </div>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={() =>
                      setCurrentTip(
                        currentTip % 4 > 0
                          ? (currentTip + 1) % 4
                          : (currentTip + (1 % 4) + 4) % 4
                      )
                    }
                  >
                    arrow_circle_right
                  </span>
                </div>
              </div>

              {result &&
                result.length < 7 &&
                detectorWork &&
                (quota > 0 || (result.length < 2 && docType !== "prp") ? (
                  <div style={{ float: "right" }}>
                    <Paraphrase
                      disable={!contentEdited}
                      docType={docType}
                      writing={editedWriting}
                      setWriting={setWriting}
                      setTips={setTips}
                      float={true}
                      useQuota={
                        result.length < 2 && docType !== "prp" ? false : true
                      }
                    />
                  </div>
                ) : (
                  <div
                    className="extra"
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      zIndex: "7",
                    }}
                  >
                    <h5 style={{ color: "#6B92F7" }}>
                      修改内容后，付款及时生成润色文书
                    </h5>
                    <div
                      className="colorText"
                      style={{
                        display: "flex",
                        gap: "20px",
                        margin: "8px 1.5vw 1.6vh 1.5vw",
                      }}
                    >
                      <div>
                        ￥
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          9
                        </span>
                        <span>/1次</span>
                      </div>
                      <div>
                        ￥
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          19
                        </span>
                        <span>/3次</span>
                      </div>
                      <div>
                        ￥
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          29
                        </span>
                        <span>/5次</span>
                      </div>
                    </div>
                    <button
                      className="pay-plus-btn"
                      style={{
                        boxShadow:
                          " 0px 1px 2px 1px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.1)",
                        marginRight: "10px",
                      }}
                      onClick={() => setPlusPopupShow(true)}
                      disabled={!contentEdited}
                      title="请先修改内容后，再付款润色文书"
                    >
                      加价润色修改
                    </button>
                  </div>
                ))}
              <p style={{ color: "#475569" }}>
                不知道如何优化文书吗？联系专业文书老师协助修改→{`\u00A0\u00A0`}
                <a
                  href="/writers-list"
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  <span className="material-symbols-outlined">face_2</span>
                  {`\u00A0`}联系文书老师
                </a>
              </p>
            </div>
          </div>
        ) : (
          <div className={writing ? "tips" : "offscreen"}>
            <div style={{ marginBottom: "30px", color: "#475569" }}>
              正在产生整体反馈...
            </div>
            <Skeleton />
          </div>
        )}
      </div>
    </div>
  );
}

/*
               {firstTime && instructionShow && (
        <ResultInstruction
          docTitle={docTitle}
          handleInstruction={() => setInstructionShow(false)}
        />
      )}
       */
