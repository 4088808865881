import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import FormContext from "../context/FormCtnProvider";
import MyWritingsContext from "../context/MyWritingsProvider";
import ResultContext from "../context/ResultProvider";
import PriceContext from "../context/PriceProvider";


export function useResult() {
    return useContext(ResultContext);
}

export function useMyWritings() {
    return useContext(MyWritingsContext);
}

export function useFormCtn() {
    return useContext(FormContext);
}

export function useAuth() {
    return useContext(AuthContext);
}

export function usePrice() {
    return useContext(PriceContext);
}

