import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "./infoPages/Footer";
import axios from "axios";
import { useAuth, useMyWritings } from "./hooks/useContext";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PHONE_REGEX = /^1[3456789]\d{9}$/;

const Register = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { setLogIn, logIn, documents } = useMyWritings();

  //const userRef = useRef();
  const errRef = useRef();

  //const [user, setUser] = useState('');

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [wechat, setWechat] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  /*useEffect(() => {
        userRef.current.focus();
    }, [])*/

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(phone));
  }, [phone]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [phone, email, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = PHONE_REGEX.test(phone);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = EMAIL_REGEX.test(email);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    setLoading(true);
    const signUp = async function () {
      //Post data to srver
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}api/v1/user/signup`,
          data: {
            name: phone,
            email: email,
            password: pwd,
            passwordConfirm: matchPwd,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log("success", "Signed up successfully!");
          setSuccess(true);
          //console.log(res.data);
          setAuth({ user: phone, pwd });
          setLogIn(true);
          navigate("/myWritings", {
            state: {
              from: "rcm",
            },
          });
        }
      } catch (err) {
        //console.log(err.response.data);
        if (err.response.data.message.includes("duplicate key")) {
          setErrMsg(
            "这个邮箱/ 手机号已经注册过了，请直接登入，若忘记密码请使用忘记密码功能"
          );
        } else {
          setErrMsg("註冊失败,请再试一次");
        }
        //alert("error", "Error log in! Try again.");
      }
    };
    signUp();
  };

  return (
    <div className="infoP" style={{ marginTop: "100px" }}>
      {success ? (
        <div className="loginPage">
          <div className="register">
            <h1>注册成功</h1>
            <div>请稍待页面跳转</div>
          </div>
        </div>
      ) : (
        <div className="loginPage">
          <div className="register">
            <h1>注册</h1>
            <form onSubmit={handleSubmit}>
              <label htmlFor="phone">中国手机号*</label>
              <input
                type="phone"
                id="phone"
                autoComplete="off"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
                aria-invalid={validPhone ? "false" : "true"}
                aria-describedby="phonenote"
                onFocus={() => setPhoneFocus(true)}
                onBlur={() => setPhoneFocus(false)}
              />
              <p
                id="phonenote"
                className={
                  phoneFocus && phone && !validPhone
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                手机格式不正确
              </p>

              <label htmlFor="email">Email 邮箱*</label>
              <input
                type="email"
                id="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <p
                id="emailnote"
                className={
                  emailFocus && email && !validEmail
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                邮箱格式不正确
              </p>

              <label htmlFor="password">密码*</label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <p
                id="pwdnote"
                className={
                  pwdFocus && pwd && !validPwd ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                6-24位，须包含英文大写、小写及数字
              </p>

              <label htmlFor="confirm_pwd">确认密码*</label>
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                密码不一致
              </p>

              <label htmlFor="wechat">微信号</label>
              <input
                type="text"
                id="wechat"
                autoComplete="off"
                onChange={(e) => setWechat(e.target.value)}
                value={wechat}
              />
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>

              <button
                style={{ marginTop: "30px" }}
                disabled={
                  !validEmail ||
                  !validPhone ||
                  !validPwd ||
                  !validMatch ||
                  loading
                    ? true
                    : false
                }
              >
                {loading ? "注册中..." : "注册"}
              </button>
            </form>

            <p>
              已经有账号？点击
              <span className="line">
                <Link to="/login" style={{ textDecoration: "underline" }}>
                  此处登录
                </Link>
              </span>
            </p>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Register;
