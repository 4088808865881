import React from "react";
import { useNavigate } from "react-router-dom";
import avatar_1 from "../assets/animal_1.svg";
import avatar_2 from "../assets/animal_2.svg";
import avatar_3 from "../assets/animal_3.svg";
import avatar_4 from "../assets/animal_4.svg";
import Cora from "../assets/Cora.svg";
import jupiter from "../assets/jupiter.svg";
import Jen from "../assets/Jen.svg";
import 唐 from "../assets/唐.svg";
import UiHint from "../components/UiHint";

export default function FormContent() {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  const writers = [
    {
      img: avatar_1,
      name: "Cora学姐",
      intro:
        "本科天津大学化工专业，硕士宾夕法尼亚大学工程学院双学位（计算机、化工）。主要负责计算机相关专业、理工专业申请的文书写作和润色",
      profession: ["CS", "理工", "转码"],
      nations: ["北美", "英国", "港澳新"],
      price: ["润色300-600元"],
      QRcode: Cora,
    },
    {
      img: avatar_2,
      name: "Jupiter 老師",
      intro:
        "丹麦数据工程师，本科心理学，硕士哥本哈根大学跨领域项目：神经科学与数据科学，在清华大学担任过短期研究助理。 文书从业经验4年+",
      profession: ["CS", "转码", "数据科学", " 社科", "心理学", "神经科学"],
      nations: ["北欧", " 荷兰"],
      price: ["润色300-600元"],
      QRcode: jupiter,
    },
    {
      img: avatar_3,
      name: "Jen",
      intro:
        "本科211金融学，硕士UCL健康经济学, 雅思写作8分，新东方、启德、新通等大型留学机构文书从业经验5年+",
      profession: [
        "金融",
        "经济",
        "医学",
        "公共卫生",
        "语言学",
        "社会学",
        "教育学",
      ],
      nations: ["美国", "英国", "港澳新", "澳洲"],
      price: ["咨询 200元/0.5h", "润色300-600元"],
      QRcode: Jen,
    },
    {
      img: avatar_4,
      name: "唐建锋",
      intro:
        "从业14年 擅长英美留学 擅长理工科和艺术留学以及英语系主流国家DIY指导",
      profession: ["理工", "艺术留学"],
      nations: ["美国", "英国", "加拿大", "港澳新", "爱尔兰"],
      price: ["直接联系"],
      QRcode: 唐,
    },
  ];

  return (
    <div className="result">
      <h3 className="title" style={{ flex: "auto" }}>
        联系文书老师协助修改
      </h3>
      <button
        className="secondary_btn"
        style={{ marginBottom: "30px" }}
        onClick={handleOnClick}
      >
        <span className="material-symbols-rounded">undo</span> 返回英文文书
      </button>

      {writers.map((writer) => (
        <div style={{ fontSize: "14px" }}>
          <div
            style={{
              height: "130px",
              padding: "10px 0px",
              margin: "0px 0px 30px 0px",
              display: "flex",
              gap: "16px",
            }}
          >
            <img className="avatar" src={writer.img} alt="Avatar" />
            <div style={{ width: "20vw", margin: "0", color: "#0F172A" }}>
              <h5>{writer.name}</h5>
              <p style={{ margin: "0" }}>{writer.intro}</p>
            </div>
            <div
              style={{
                borderLeft: "solid 1px #D9D5EC",
                width: "36vw",
                paddingLeft: "20px",
                margin: "0",
                lineHeight: "38px",
              }}
            >
              <span>专业：</span>
              {writer.profession.map((tagP) => (
                <span className="tag">{tagP}</span>
              ))}
              <br />
              <span>国家：</span>
              {writer.nations.map((tagN) => (
                <span className="tag">{tagN}</span>
              ))}
              <br />
              <span>价格：</span>
              {writer.price.map((tagP) => (
                <span className="tag">{tagP}</span>
              ))}
            </div>
            <div
              style={{
                borderLeft: "solid 1px #D9D5EC",
                paddingLeft: "20px",
              }}
            >
              <img
                className="contact-code"
                src={writer.QRcode}
                alt="contact-code"
              />
            </div>
          </div>
        </div>
      ))}

      <div
        style={{
          position: "fixed",
          bottom: "0",
          backgroundColor: "white",
          width: "100%",
        }}
      ></div>
      <UiHint
        content={
          " 文书老师并不代表落花生团队。如需联系，请直接与文书老师联络。"
        }
        icon={<span class="material-symbols-outlined">info</span>}
      />
    </div>
  );
}
