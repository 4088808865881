import React, { useEffect } from "react";
import { createContext, useState } from "react";

const PriceContext = createContext({});

export const PriceProvider = ({ children }) => {
  const [rcmPrice, setRcmPrice] = useState(75);
  const [sopPrice, setSopPrice] = useState(100);
  const [prpPrice, setPrpPrice] = useState(35);

  const [prpOne, setPrpOnce] = useState(9);
  const [prpThree, setPrpThreetimes] = useState(19);
  const [prpFive, setPrpFivetimes] = useState(29);

  const [promotionLable, setPromotionLable] = useState(true);
  const [lableContent, setLableContent] = useState("试营运期间一律半价");
  const [promotionAlldocs, setPromotionAlldocs] = useState(0.5);
  const [promotionFirstTime, setPromotionFirstTime] = useState(false);
  const [firstTimePrice, setFirstTimePrice] = useState(6.66);

  return (
    <PriceContext.Provider
      value={{
        rcmPrice,
        sopPrice,
        prpPrice,
        prpOne,
        prpThree,
        prpFive,
        promotionLable,
        lableContent,
        promotionAlldocs,
        promotionFirstTime,
        firstTimePrice,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};

export default PriceContext;
