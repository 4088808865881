import React, { useEffect } from "react";
import { createContext, useState } from "react";
import axios from "axios";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState();

  useEffect(() => {
    if (!auth) {
      const ifLoggedIn = async () => {
        try {
          const res = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}api/v1/user/ifLoggedIn`,
            withCredentials: true,
          });
          if (res.data.status === "success") {
            //console.log(res.data.message);
            //console.log(res.data.data);
            setAuth(res.data.data);
          }
        } catch (err) {
          console.log(err.response);
        }
      };
      ifLoggedIn();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
