import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useFormCtn } from "../hooks/useContext";
import { SopTabItems, RcmTabItems, PrpTabItems } from "./TabItems";
import checked from "../assets/checked.svg";
import unchecked from "../assets/unchecked.svg";
import active from "../assets/active.svg";
import { Tooltip } from "react-tooltip";

export default function Tabs() {
  const location = useLocation();
  const {
    setDocId,
    progress,
    docTitle,
    docType,
    setTitleChanged,
    typedTitle,
    setTypedTitle,
  } = useFormCtn();

  const { docNumber } = useParams();
  const navigate = useNavigate();
  const curIndex = Number(location.pathname.slice(1, 2));

  const [activeTabIndex, setActiveTabIndex] = useState(curIndex);
  const [saveHint, setSaveHint] = useState(false);

  const tabItemMap = new Map([
    ["rcm", RcmTabItems],
    ["sop", SopTabItems],
    ["prp", PrpTabItems],
  ]);

  const titleMap = new Map([
    ["rcm", "推荐信"],
    ["sop", "个人陈述"],
    ["prp", "润色文件"],
  ]);

  useEffect(() => {
    setDocId(docNumber);
  }, [docNumber]);

  useEffect(() => {
    docTitle ? setTypedTitle(docTitle) : setTypedTitle("");
  }, [docTitle]);

  useEffect(() => {
    setActiveTabIndex(curIndex);
  }, [curIndex]);

  const activateTab = (index) => {
    setActiveTabIndex(index);
    navigate(`${tabItemMap.get(docType)[index].path}${docNumber}`);
  };

  useEffect(() => {
    if (Boolean(typedTitle) === Boolean(docTitle)) {
      //console.log(Boolean(typedTitle), Boolean(docTitle));
      setTitleChanged(false);
      //console.log("title not changed");
    } else if (Boolean(typedTitle) !== Boolean(docTitle)) {
      //console.log(Boolean(typedTitle), Boolean(docTitle));
      setTitleChanged(true);
      //console.log("new title");
    }
  }, [typedTitle]);

  return (
    <div className="TabSect">
      {docType && (
        <>
          <input
            type="text"
            name="title"
            placeholder={`未命名${titleMap.get(docType)}`}
            value={typedTitle}
            onChange={(e) => {
              setTypedTitle(e.target.value);
            }}
            required
          ></input>
        </>
      )}
      {(docType === "rcm" || docType === "sop" || docType === "prp") && (
        <div className="tabs">
          {tabItemMap.get(docType).map((tab, index) => (
            <div
              key={index}
              className={index === activeTabIndex ? `activeTab` : `tab`}
              onClick={() => activateTab(index)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {index === activeTabIndex && (
                  <img
                    alt="active"
                    src={active}
                    className={
                      progress && progress[index] === true ? "noShow" : ""
                    }
                  />
                )}
                {progress && progress[index] === true ? (
                  <>
                    <img
                      alt="checked"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="记得先储存问卷!"
                      src={checked}
                    />
                    <Tooltip id="my-tooltip" />
                  </>
                ) : (
                  <>
                    <img
                      alt="unchecked"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="记得先储存问卷!"
                      src={unchecked}
                      className={index === activeTabIndex ? "noShow" : ""}
                    />
                    <Tooltip id="my-tooltip" />
                  </>
                )}

                {progress && progress[index] === true ? (
                  <div
                    className={
                      index === tabItemMap.get(docType).length - 1
                        ? "whiteLine"
                        : "checkedLine"
                    }
                  ></div>
                ) : (
                  <div
                    className={
                      index === tabItemMap.get(docType).length - 1
                        ? "whiteLine"
                        : "uncheckedLine"
                    }
                  ></div>
                )}
              </div>
              <div
                style={{
                  position: "relative",
                  left: "-40px",
                  marginTop: "8px",
                }}
              >
                {tab.title}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
