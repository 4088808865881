import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import axios from "axios";

//推薦信_推薦人資料

export default function RcmRcmdForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const {
    docId,
    docTitle,
    formCtn,
    setFormCtn,
    setProgress,
    titleChanged,
    typedTitle,
  } = useFormCtn();
  const [recommender, setRecommender] = useState("");
  const [position, setPosition] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [profession, setProfession] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (formCtn && formCtn[1] && formCtn[1][0]) {
      setRecommender(formCtn[1][0]);
      setPosition(formCtn[1][1]);
      setOrganisation(formCtn[1][2]);
      setProfession(formCtn[1][3]);
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[1] && formCtn[1].length === 4) {
        if (
          recommender !== formCtn[1][0] ||
          position !== formCtn[1][1] ||
          organisation !== formCtn[1][2] ||
          profession !== formCtn[1][3]
        ) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && formCtn[1] && formCtn[1].length < 4) {
        if (recommender || position || organisation || profession) {
          setFormChanged(true);
          //console.log("typed new");
        } else {
          setFormChanged(false);
        }
      }
    };
    recommender &&
      position &&
      organisation &&
      profession &&
      checkIfCtnChanged();
  }, [recommender, position, organisation, profession]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [
              formCtn[0],
              [recommender, position, organisation, profession],
              formCtn[2],
            ],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/2rcmCntForm/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };
    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/2rcmCntForm/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div>
          <dd className="subtitle">推荐人姓名（请填写英文拼音）</dd>
          <input
            type="text"
            name="recommender"
            value={recommender}
            onChange={(e) => setRecommender(e.target.value)}
            placeholder="示例：Meimei Han"
            required
          />
        </div>
        <div>
          <dd className="subtitle">职称 （请填写英文名称）</dd>
          <input
            type="text"
            name="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            placeholder="示例：Professor"
            required
          />
        </div>
        <div>
          <dd className="subtitle">任职机构（请填写英文名称）</dd>
          <input
            type="text"
            name="organisation"
            value={organisation}
            onChange={(e) => setOrganisation(e.target.value)}
            placeholder="示例：Tsinghua University"
            required
          />
        </div>
        <div>
          <dd className="subtitle">任职专业</dd>
          <input
            type="text"
            name="profession"
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            placeholder="示例：Computer Science"
            required
          />
        </div>
        <button
          className="float_btn"
          disabled={
            recommender && position && organisation && profession ? false : true
          }
        >
          储存/下一步
        </button>
      </form>
    </div>
  );
}
