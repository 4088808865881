import React, { useEffect, useState } from "react";
import { useFormCtn, useResult } from "../hooks/useContext";
import { useNavigate, useParams } from "react-router-dom";

export default function TabsResult() {
  const navigate = useNavigate();

  const { docTitle, setDocId, docType } = useFormCtn();
  const { result, setVersion } = useResult();
  const { docNumber, version } = useParams();
  const curIndex = Number(version);

  const [activeTabIndex, setActiveTabIndex] = useState(curIndex);

  useEffect(() => {
    setDocId(docNumber);
  }, [docNumber]);

  useEffect(() => {
    setVersion(version);
    setActiveTabIndex(curIndex);
  }, [version]);

  const activateTab = (index) => {
    setActiveTabIndex(index);
    navigate(`/_${docType}Result/${docNumber}/${index}`);
  };

  return (
    <div className="TabsResult">
      <div className="resultTabs">
        {/*
                    {result && version &&
                        <div className='tabsResult'>
                            {result.map(([], index) => (
                                <div
                                    key={index}
                                    className={index === activeTabIndex && result.length > 1 ? `activeResultTab` : `resultTab`}
                                    onClick={() => activateTab(index)}
                                >
                                    {index === 0 ? <span>推薦信初稿</span> : <span>推薦信修改-{index}</span>}
                                </div>
                            ))}
                        </div>}
                            */}

        {result &&
          Array.from({ length: result.length }, (_, index) => (
            <div
              key={index}
              className={
                index === activeTabIndex ? `activeResultTab` : `resultTab`
              }
              onClick={() => activateTab(index)}
            >
              {index === 0 ? <span>初稿</span> : <span>润色-{index}</span>}
            </div>
          ))}
      </div>
    </div>
  );
}
