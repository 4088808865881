import React from "react";
import "./semiCircle.css";

const SemiCircleProgress = ({ percentage }) => {
  const deg = Number(percentage) * 1.8;
  const color = Number(percentage) > 20 ? "#DB5461" : "#4BC6B9";
  const str = "< 5%";

  return (
    <>
      <div className="chart">
        <div
          style={{ transform: `rotate(${deg}deg)`, borderColor: `${color}` }}
        >
          <span className="candidate-one-percentage"></span>
          <span className="candidate-two-percentage"></span>
        </div>
      </div>
      <div style={{ zIndex: "8", position: "relative", top: "-36px" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "28px",
            fontFamily: "PingFangSC-Semibold, sans-serif",
            color: color,
          }}
        >
          {Number(percentage) < 5 ? <>{str}</> : <>{Math.trunc(percentage)}%</>}
        </div>
        {typeof Number(percentage) === "number" && percentage !== undefined ? (
          <>
            {Number(percentage) < 20 ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginBottom: "-15px",
                }}
              ></div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginBottom: "-15px",
                }}
              ></div>
            )}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default SemiCircleProgress;
