import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FormCntProvider } from "./context/FormCtnProvider";
import { MyWritingsProvider } from "./context/MyWritingsProvider";
import { ResultProvider } from "./context/ResultProvider";
import { PriceProvider } from "./context/PriceProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PriceProvider>
        <AuthProvider>
          <MyWritingsProvider>
            <FormCntProvider>
              <ResultProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </ResultProvider>
            </FormCntProvider>
          </MyWritingsProvider>
        </AuthProvider>
      </PriceProvider>
    </BrowserRouter>
  </React.StrictMode>
);
