import React, { useState } from "react";
import warningImg from "../assets/warning.svg";

export default function UiWarning() {
  const [warning, setWarning] = useState(true);

  return (
    <>
      {warning && (
        <div className="warning" style={{ margin: "0" }}>
          <img
            alt="warning"
            src={warningImg}
            style={{ padding: "0 10px 0 40px", minWidth: "80px" }}
          />
          <p style={{ fontSize: "16px", fontWeight: "600", flex: "auto" }}>
            我们的服务暂不支持手机界面，请使用平板或电脑注册登陆。
          </p>
          <button
            style={{ backgroundColor: "#6B92F7", flex: "none", width: "60px" }}
            onClick={() => setWarning(false)}
          >
            <span className="material-symbols-rounded hover">close</span>
          </button>
        </div>
      )}
    </>
  );
}
