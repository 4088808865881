import React from 'react'
import { Outlet } from "react-router-dom"
import Sidebar from './Sidebar'
import Warning from './Warning'



export default function LayoutSidebar() {
    return (
        <div>
            <Warning />
            <Sidebar />
            <main className="App">
                <Outlet />
            </main>
        </div>
    )
}
