import React, { useState, useEffect } from "react";
import { useMyWritings } from "../hooks/useContext";

export default function Account() {
  const { setLogIn, user, email, wechat } = useMyWritings();

  // const [success, setSuccess] = useState(false);
  // const [errMsg, setErrMsg] = useState("");

  // const handleReset = () => {
  //   setSuccess(false);
  //   setErrMsg("");
  // };

  // const handleDelete = async function () {
  // };

  return (
    <div className="account">
      <h3 style={{ marginBottom: "50px" }}>账户资讯</h3>
      <div className="rows">
        <h4 style={{ width: "20vw", margin: "0" }}>账号</h4>
        <div>
          <li className="rows">
            <div style={{ width: "10vw" }}>手机号</div>
            <div>{user}</div>
          </li>
          <li className="rows">
            <div style={{ width: "10vw" }}>Email邮箱</div>
            <div>{email}</div>
          </li>
          <li className="rows">
            <div style={{ width: "10vw" }}>微信号</div>
            <div>{wechat}</div>
          </li>
        </div>
      </div>
      {/*<div className="rows">
        <h4 style={{ width: "20vw", margin: "0" }}>密码</h4>
        <button onClick={handleOnClick} style={{ margin: "0" }}>
          修改密码
        </button>
        {success && (
          <span style={{ padding: "10px", color: "gray" }}>
            {" "}
            重设密码的电子邮件已成功发送！{" "}
          </span>
        )}
        {errMsg && <span>{errMsg}</span>}
      </div>
      <button onClick={handleDelete}>deleteUser</button>*/}
    </div>
  );
}
