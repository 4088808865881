import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import rcmInfo from "../assets/rcmInfo.png";
import Footer from "./Footer";
import NavLinks from "./NavLinks";
import UiWarning from "./UiWarning";
import { usePrice } from "../hooks/useContext";

export default function RcmInfo() {
  const navigate = useNavigate();
  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    promotionLable,
    lableContent,
    promotionAlldocs,
  } = usePrice();

  const handleRcm = () => {
    navigate("/myWritings", {
      state: {
        from: "rcm",
      },
    });
  };
  const handleSop = () => {
    navigate("/myWritings", {
      state: {
        from: "sop",
      },
    });
  };

  const handlePrp = () => {
    navigate("/myWritings", {
      state: {
        from: "prp",
      },
    });
  };

  const [className, setClassName] = useState("sticky");

  const handleScroll = (className) => {
    if (className !== "afterStickyRcm" && window.pageYOffset >= 1758) {
      setClassName("afterStickyRcm");
    } else if (className === "afterStickyRcm" && window.pageYOffset < 1758) {
      setClassName("sticky");
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    window.onscroll = () => handleScroll(className);
  }, [className]);

  return (
    <div style={{ textAlign: "left" }}>
      <img
        alt="RL-Recommendation Letter"
        src={rcmInfo}
        style={{ margin: "-30px 0 -60px 0", width: "100vw" }}
      />
      <div
        style={{
          position: "relative",
          top: "-100px",
          left: "80px",
          color: "white",
        }}
      >
        <h2>Recommendation Letter</h2>
        <h1>推荐信撰写</h1>
      </div>
      <div className="threeInfo infoP">
        <div style={{ margin: "0", marginLeft: "-20px" }}>
          <UiWarning />
        </div>
        <NavLinks />

        <div className="dynamic" style={{ margin: "0" }}>
          <div
            className={className}
            style={{ margin: "0", paddingBottom: "40px" }}
          >
            <h4 style={{ marginBottom: "16px" }}>
              推荐信 Recommendation Letter
            </h4>
            <div style={{ margin: "30px 0" }}>
              <p>
                适用于无初稿的申请人。
                <br />
                我们的智能 AI
                将根据您填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、 1
                次免费润色服务、以及文书终稿。
              </p>
              <p>约300-500单词</p>
            </div>
            <div
              className={!promotionLable ? "offscreen" : undefined}
              style={{
                backgroundColor: "#DB5461",
                color: "white",
                padding: "0 5px",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              {lableContent}
            </div>
            <p className="colorText" style={{ margin: "40px 0 30px 0" }}>
              <span className={promotionLable ? "deletePrice" : "biggerText"}>
                ¥{rcmPrice}&nbsp;
              </span>
              <span className="redText">&nbsp;¥</span>
              <span className="biggerText">{rcmPrice * promotionAlldocs}</span>
            </p>
            <button onClick={handleRcm}>选择服务</button>
          </div>

          <div
            className="detailInfo"
            style={{ marginLeft: "auto", marginRight: "0" }}
          >
            <div style={{ margin: "0" }}>
              <h2 className="colorText infoTopic">目的</h2>
              <p>
                站在第三人的角度，提供正面评价，替您的能力或潜力背书。推荐人背景最好
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  和想申请的领域有直接相关
                </span>
                ，而且和您有
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  足够深入的互动
                </span>
                。
              </p>
              <p>
                如果您还是学生，可以邀请
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  学校教授
                </span>
                写推荐信。如果您已经工作，也可邀请
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  企业领导
                </span>
                作为推荐人。
              </p>
            </div>
            <div style={{ margin: "0" }}>
              <h2 className="colorText infoTopic">架构</h2>
              <p>要写出一封有血有肉的推荐信，您要准备以下内容：</p>
              <h5>01 关系说明</h5>{" "}
              <p>
                说明您与推荐人的关系。你们如何认识的，以及你们已经认识多长时间。
              </p>
              <h5>02 评价和事例 </h5>{" "}
              <p>
                证这是推荐信的主体部分，需要具体说明您的能力或成就，并提供例子或故事作为支持。
              </p>
              <h5>03 推荐结语</h5>{" "}
              <p>
                总结整体评价，并明确表示推荐人希望为您推荐到什么项目。在这里再次强调您的优秀特质。
              </p>
              <h5>04 结束语和签名</h5>{" "}
              <p>最后，礼貌地结束信件，并提供推荐人的联系方式和单位。</p>
            </div>
            <div style={{ margin: "-40px 0 0 0" }}>
              <h2 className="colorText infoTopic" style={{ top: "103px" }}>
                服务
                <br />
                流程
              </h2>
              <h5>Step 01</h5>
              <p>点击“选择服务”按钮，进入推荐信生成流程。</p>
              <h5>Step 02</h5>
              <p>根据提示填写您的个人信息、学术成就、技能和经历等。</p>
              <h5>Step 03</h5>
              <p>
                提交信息並付款后&#65292;我们的AI系统将即时为您生成推荐信英文初稿和整体反馈。
              </p>
              <h5>Step 04</h5>
              <p>
                使用我们赠送的 1 次免费润色服务。根据 AI
                反馈，在初稿上直接加入中文修改内容。
              </p>
              <h5>Step 05</h5>
              <p>
                提交润色修改&#65292;AI助手将即时为您生成推荐信英文终稿。英文稿件完成后&#65292;您可以随时登录查看所有文书。{" "}
              </p>
            </div>
          </div>
        </div>

        <hr
          style={{
            position: "relative",
            left: "-80px",
            width: "100vw",
            height: "1px",
            borderWidth: "0",
            backgroundColor: "#E2E8F0",
            color: "#E2E8F0",
            margin: "80px 0",
          }}
        ></hr>

        <div style={{ margin: "0" }}>
          <div style={{ margin: "0" }}>
            <h2 style={{ marginBottom: "20px" }}>其他服务</h2>
            <h4 style={{ color: "#475569", marginBottom: "24px" }}>
              你可以选择直接生成推荐信和个人陈述，或者润色已有文书{" "}
            </h4>
          </div>
          <div className="infoDivs" style={{ margin: "0" }}>
            <div className="infoDiv">
              <h4 style={{ marginBottom: "16px" }}>
                个人陈述 Statement of Purpose
              </h4>
              <div className="info">
                <p>
                  适用于无初稿的申请人
                  。根据用户填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、润色修改，以及文书终稿。{" "}
                  <Link
                    to="/sopInfo"
                    style={{ color: "#6B92F7", textDecoration: "underline" }}
                  >
                    查看详情
                  </Link>
                </p>
                <p>约800-1000单词</p>
              </div>
              <div
                className={!promotionLable ? "offscreen" : undefined}
                style={{
                  backgroundColor: "#DB5461",
                  color: "white",
                  padding: "0 5px",
                  width: "fit-content",
                  marginBottom: "20px",
                }}
              >
                {lableContent}
              </div>
              <div>
                <span
                  className="colorText "
                  style={{ position: "absolute", bottom: "30px" }}
                >
                  <span
                    className={promotionLable ? "deletePrice" : "biggerText"}
                  >
                    ¥{sopPrice}&nbsp;
                  </span>
                  <span className="redText">&nbsp;¥</span>
                  <span className="biggerText">
                    {sopPrice * promotionAlldocs}
                  </span>
                </span>
                <button
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "32px",
                  }}
                  onClick={handleSop}
                >
                  选择服务
                </button>
              </div>
            </div>
            <div className="infoDiv">
              <h4 style={{ marginBottom: "16px" }}>文书润色</h4>
              <div className="info">
                <p>
                  适用于有英文初稿、需要语言/拼写/行文逻辑修改的申请人。对文书进行语句修正、结构调整。
                  <Link
                    to="/prpInfo"
                    style={{ color: "#6B92F7", textDecoration: "underline" }}
                  >
                    查看详情
                  </Link>
                </p>
                <p>推荐信500单词內/个人陈述1000单词內</p>
              </div>
              <div
                className={!promotionLable ? "offscreen" : undefined}
                style={{
                  backgroundColor: "#DB5461",
                  color: "white",
                  padding: "0 5px",
                  width: "fit-content",
                  marginBottom: "20px",
                }}
              >
                {lableContent}
              </div>
              <div>
                <span
                  className="colorText"
                  style={{ position: "absolute", bottom: "30px" }}
                >
                  <span
                    className={promotionLable ? "deletePrice" : "biggerText"}
                  >
                    ¥{prpPrice}&nbsp;
                  </span>
                  <span className="redText">&nbsp;¥</span>
                  <span className="biggerText">
                    {prpPrice * promotionAlldocs}
                  </span>
                </span>
                <button
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "32px",
                  }}
                  onClick={handlePrp}
                >
                  选择服务
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}

//className={sticky ? 'sticky' : 'afterSticky'}

/*
    const changeOnScroll = () => {
        //document.documentElement.scrollTop > 1615 ? setSticky(false) : setSticky(true);
    };

    //window.addEventListener('scroll', changeOnScroll);
*/

//style={{ width: "43vw", marginTop: "-430px", marginLeft: "auto", marginRight: "0" }}
