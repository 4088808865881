import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useFormCtn, usePrice, useAuth } from "../hooks/useContext";
import ErrMessage from "../components/ErrMessage";
import axios from "axios";

export default function Stripe({ docType, plan }) {
  const { auth } = useAuth();
  const { docId } = useFormCtn();
  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    prpOne,
    prpThree,
    prpFive,
    promotionAlldocs,
  } = usePrice();

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSct] = useState("");

  const [price, setPrice] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const priceMap = new Map([
    ["rcm", rcmPrice * 100 * promotionAlldocs],
    ["sop", sopPrice * 100 * promotionAlldocs],
    ["prp", prpPrice * 100 * promotionAlldocs],
  ]);

  const priceMap_Prp = new Map([
    ["1", prpOne * 100],
    ["3", prpThree * 100],
    ["5", prpFive * 100],
  ]);

  const titleMap = new Map([
    ["rcm", "推荐信"],
    ["sop", "个人陈述"],
    ["prp", "润色文件"],
  ]);

  useEffect(() => {
    if (plan) {
      setPrice(priceMap_Prp.get(plan));
    } else {
      setPrice(priceMap.get(docType));
    }
  }, []);

  useEffect(() => {
    const config = async function () {
      try {
        const res = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/payment/publishableKey`,
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data);
          //console.log(res.data.publishableKey);
          const publishableKey = res.data.publishableKey;
          setStripePromise(loadStripe(publishableKey));
        }
      } catch (error) {
        console.error("Error getting stripe PublishableKey", error);
        setErrMsg("连线失败,请再试一次");
      }
    };
    config();
  }, []);

  useEffect(() => {
    const createPaymentIntent = async function () {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}api/v1/payment/paymentIntent/${docId}`,
          data: {
            docType: docType,
            plan: plan,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.paymentIntent.client_secret);
          setClientSct(res.data.paymentIntent.client_secret);
        }
      } catch (error) {
        console.error("Error creating paymentIntent", error);
        setErrMsg("连线失败,请再试一次");
      }
    };
    createPaymentIntent();
  }, []);

  const handleError = () => {
    setErrMsg("");
    window.location.reload(false);
  };

  return (
    <>
      {errMsg && <ErrMessage message={errMsg} handleClose={handleError} />}
      {plan && (
        <div style={{ display: "flex" }}>
          <h3 className="title">付款</h3>
        </div>
      )}
      <div
        className="s-table-container"
        style={{ margin: "10px 0px 30px 0px" }}
      >
        <table className="table">
          <thead>
            <tr>
              <th scope="col">服务项目</th>
              <th scope="col">金额</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {plan && (
                  <>
                    <div>加价润色 x {plan}</div>
                    <div>(立即使用1次{titleMap.get(docType)}润色额度)</div>
                  </>
                )}
                {!plan && docType === "rcm" && "推荐信"}
                {!plan && docType === "sop" && "个人陈述"}
                {!plan && docType === "prp" && "润色"}
              </td>
              <td>￥{price / 100}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ textAlign: "left" }}>选择支付方式：</p>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm setErrMsg={setErrMsg} />
        </Elements>
      )}
    </>
  );
}
