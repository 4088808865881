import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import axios from "axios";

export default function PrpForm() {
  const navigate = useNavigate();

  const { docId, typedTitle, formCtn, setFormCtn, setProgress, titleChanged } =
    useFormCtn();
  const [type, setType] = useState("");
  const [draft, setDraft] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (formCtn && formCtn[0] && formCtn[0][0]) {
      if (formCtn[0].length === 0) {
        console.log("set form empty");
        setType("");
        setDraft("");
      } else {
        console.log("set form from db");
        setType(formCtn[0][0]);
        setDraft(formCtn[0][1]);
      }
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[0] && formCtn[0].length === 2) {
        if (type !== formCtn[0][0] || draft !== formCtn[0][1]) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && formCtn[0] && formCtn[0].length < 2) {
        if (type || draft) {
          setFormChanged(true);
          //console.log("typed new");
        } else {
          setFormChanged(false);
        }
      }
    };
    type && draft && checkIfCtnChanged();
  }, [type, draft]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [[type, draft]],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          navigate(`/1prpPayment/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };

    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/1prpPayment/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div
          className="radioButtuns"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <dd className="type">文书类别</dd>
          <label htmlFor="rcm" for="rcm">
            <input
              className="radio"
              type="radio"
              name="type"
              value="rcm"
              checked={type === "rcm" ? true : false}
              onChange={(e) => setType(e.target.value)}
              id="rcm"
            />
            推荐信
          </label>

          <label htmlFor="sop" for="sop">
            <input
              className="radio"
              type="radio"
              name="type"
              value="sop"
              checked={type === "sop" ? true : false}
              onChange={(e) => setType(e.target.value)}
              id="sop"
            />
            个人陈述
          </label>

          {/*<select
                        type="text"
                        name="type"
                        value={prpForm.type}
                        onChange={handleInputChange}
                        required
                    >
                        <option>選擇類別</option>
                        <option value="Rcm">推薦信</option>
                        <option value="Sop">SOP</option>
    </select>*/}
        </div>

        <div>
          <dd className="draft">英文文书初稿</dd>
          <textarea
            type="text"
            name="draft"
            value={draft}
            onChange={(e) => setDraft(e.target.value)}
            required
            style={
              type === "sop"
                ? {
                    width: "80%",
                    minHeight: "340px",
                    height: `${count * 0.4}px`,
                  }
                : {
                    width: "80%",
                    minHeight: "340px",
                    height: `${count * 0.5}px`,
                  }
            }
          ></textarea>
        </div>
        <button className="float_btn" disabled={type && draft ? false : true}>
          储存/下一步
        </button>
      </form>
    </div>
  );
}
