import React, { useEffect } from "react";
import GenerateWriting from "./GenerateWriting";
import { useFormCtn, useMyWritings, useResult } from "../hooks/useContext";
import { useLocation, useParams } from "react-router-dom";

export default function CompletionBackup() {
  const popUpBoxStyles = {
    position: "fixed",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
    justifyContent: "center",
    alignItems: "center",
  };
  const boxStyles = {
    width: "30vw",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "left",
    letterSpacing: "3px",
  };
  const titleStyles = {
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: "15px",
    color: "#0F172A",
  };

  return (
    <div>
      <div className="Popup" style={popUpBoxStyles}>
        <div className="box" style={boxStyles}>
          <div style={titleStyles}>🎉付款成功</div>
          <div style={{ color: "#475569" }}>
            请点击”生成文书”按钮，我们会立即为你生成英文文书！
          </div>
          <div style={{ float: "right", marginTop: "20px" }}>
            <GenerateWriting />
          </div>
        </div>
      </div>
    </div>
  );
}
