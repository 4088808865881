import React, { useEffect, useState } from "react";
import { useMyWritings } from "../hooks/useContext";
import { useLocation, useParams } from "react-router-dom";
import Paraphrase from "../components/Paraphrase";
import axios from "axios";

export default function CompletionPlus({ docType, writing }) {
  const popUpBoxStyles = {
    position: "fixed",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
    justifyContent: "center",
    alignItems: "center",
  };
  const boxStyles = {
    width: "30vw",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "left",
    letterSpacing: "3px",
  };
  const titleStyles = {
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: "15px",
    color: "#0F172A",
  };

  const location = useLocation();
  const { docNumber } = useParams();
  const { payments, setPayments, setQuota } = useMyWritings();

  const [editedWriting, setEditedWriting] = useState();
  const [payedPrice, setPayedPrice] = useState();
  const [errMsg, setErrMsg] = useState("");

  const payment_intent = location.search.slice(
    location.search.indexOf("=") + 1,
    location.search.indexOf("=") + 28
  );

  useEffect(() => {
    const getPaymentDetail = async function () {
      try {
        const res = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/payment/retrievePayment/${payment_intent}`,
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.paymentIntent);
          console.log(res.data.paymentIntent.amount / 100);
          setPayedPrice(res.data.paymentIntent.amount / 100);
        }
      } catch (error) {
        console.error("Error retrieving paymentdetail", error);
        setErrMsg("连线失败,请再试一次");
      }
    };

    //console.log(localStorage.getItem("editedWriting"));
    setEditedWriting(localStorage.getItem("editedWriting"));
    getPaymentDetail();
  }, []);

  useEffect(() => {
    const savePayment = async function () {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}api/v1/payment/${docNumber}`,
          data: {
            intentId: payment_intent,
            amount: payedPrice,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data);
          console.log("payment saved");
        }
      } catch (error) {
        console.error("Error saving paymentInfo", error);
        setErrMsg("Error saving paymentInfo");
      }
    };

    const saveQuota = async function () {
      try {
        const user = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/user`,
          withCredentials: true,
        });
        if (user.data.status === "success") {
          let currentQuota = user.data.data.user.quota;
          //console.log(currentQuota);
          if (currentQuota === undefined) currentQuota = 0;
          console.log("payedPrice", payedPrice);
          console.log(
            "try save Quota!!!",
            currentQuota + ((payedPrice + 1) / 5 - 1)
          );
          const res = await axios({
            method: "PATCH",
            url: `${process.env.REACT_APP_API_URL}api/v1/user/updateMe`,
            data: {
              quota: currentQuota + ((payedPrice + 1) / 5 - 1),
            },
            withCredentials: true,
          });
          if (res.data.status === "success") {
            let newQota = res.data.data.user.quota;
            //console.log(newQota);
            setQuota(newQota);
          }
        }
      } catch (err) {
        console.log(
          "Failed to fetch openai for saving quota!",
          err.response.data.message
        );
        setErrMsg("连接失败，请稍后再尝试一次。");
      }
    };
    if (
      location.search.slice(-9) === "succeeded" &&
      payment_intent &&
      payedPrice
    ) {
      savePayment();
      saveQuota();
    }
    //console.log(payedPrice);
  }, [payedPrice]);

  return (
    <div>
      <div className="Popup" style={popUpBoxStyles}>
        <div className="box" style={boxStyles}>
          <div style={titleStyles}>🎉付款成功</div>
          <div style={{ color: "#475569" }}>
            请点击“生成文书”按钮，我们会立即为您润色英文文书！
          </div>
          <div style={{ float: "right", marginTop: "20px" }}>
            <Paraphrase
              docType={docType}
              writing={editedWriting}
              float={false}
              useQuota={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
