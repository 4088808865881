import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import {
  useAuth,
  useFormCtn,
  useMyWritings,
  useResult,
} from "../hooks/useContext";
import ErrMessage from "../components/ErrMessage";
import UiHint from "./../components/UiHint";
import note_add from "../assets/note_add.svg";

export default function MyWritings() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { state } = useLocation();

  const {
    runMyWritingsProvd,
    setRunMyWritingsProvd,
    setLogIn,
    documents,
    quota,
    payments,
    setErrMsg,
    errMsg,
    firstTime,
  } = useMyWritings();
  const { setDocId, setDocType, setProgress, runGetCtn, setRunGetCtn } =
    useFormCtn();
  const { runGetResult, setrRunGetResult } = useResult();

  useEffect(() => {
    window.scrollTo(0, 0);
    setRunMyWritingsProvd(!runMyWritingsProvd);
  }, []);

  useEffect(() => {
    const checkFileExist = async () => {
      if (firstTime === false) {
        const filteredDoc = documents.filter((doc) => {
          return doc.docType === state?.from;
        });
        console.log(filteredDoc);
        if (filteredDoc.length > 0) {
          return;
        } else if (filteredDoc.length === 0) {
          navigate("/addFile");
        }
      } else if (firstTime === true) {
        navigate("/addFile");
      }
    };
    state?.from && documents && checkFileExist();
  }, [documents]);

  useEffect(() => {
    setLogIn(true);
  }, [auth]);

  const handleLinktoForm = (docType, docId, progress) => {
    setDocId(docId);
    setDocType(docType);
    setProgress(progress);
    const paidDoc = payments.filter((pay) => {
      return pay.docId === docId;
    });
    if (paidDoc.length > 0) {
      setRunGetCtn(!runGetCtn);
      if (docType === "rcm") navigate(`/3RcmPayment/${docId}?succeeded`);
      if (docType === "sop") navigate(`/5SopPayment/${docId}?succeeded`);
      if (docType === "prp") navigate(`/1PrpPayment/${docId}?succeeded`);
    } else {
      //if doc not-completed => go to form page
      setRunGetCtn(!runGetCtn);
      //console.log(runGetCtn);
      navigate(`/0${docType}BasicForm/${docId}`);
    }
  };
  const handleLinktoResult = (docType, docId) => {
    navigate(`/_${docType}Result/${docId}/0`);
    setrRunGetResult(!runGetResult);
  };

  let docsForEdicting;
  let docsCompleted;
  if (documents) {
    const sortedDocs = documents.sort(function (a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
    docsForEdicting = sortedDocs.filter((doc) => {
      return doc.progress[0] !== "completed";
    });
    docsCompleted = sortedDocs.filter((doc) => {
      return doc.progress[0] === "completed";
    });
  }

  return (
    <div className="myWritings">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      {!documents && (
        <UiHint
          content={"⭐️ 特别优惠 ⭐️ 第一个文书享均一优惠价 6.66 元！"}
          icon={<span>{`\u00A0\u00A0`}</span>}
        />
      )}
      {/*{deleteDoc && deleteName && popupOpen && <Popup function={'delete'} content={`確定要刪除${titleMap.get(deleteDoc.slice(0, 3))}${deleteName}嗎? 文件刪除後將無法恢復。`} handleClose={() => { setPopupOpen(false) }} deleteDoc={deleteDoc} />}*/}
      <h3>我的文书</h3>
      <div className="docsSec">
        <h4>编辑中</h4>
        <div className="docDivs">
          <div className="docDiv">
            <div
              className="goToDoc"
              onClick={() => {
                navigate("/addFile");
              }}
            >
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  margin: "30px 0px 15px",
                }}
              >
                <img alt="note_add" src={note_add} />
              </div>
              <div
                style={{
                  height: "30%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <h4 style={{ color: "#6B92F7" }}>新增文书</h4>
              </div>
            </div>
          </div>
          {documents && docsForEdicting && (
            <>
              {docsForEdicting.map(
                ({ docName, docType, progress, updatedAt, _id }, index) => (
                  <div className="docDiv" key={index}>
                    <div
                      className="goToDoc"
                      onClick={() => {
                        handleLinktoForm(docType, _id, progress);
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>
                        {docType === "rcm" ? "推荐信" : ""}
                        {docType === "sop" ? "个人陈述" : ""}
                        {docType === "prp" ? "文书润色" : ""}
                      </div>
                      <div className="docTitle">
                        {docName ? docName : "未命名文件"}
                      </div>
                      <ProgressBar
                        numerator={progress.filter((el) => el === true).length}
                        denominator={progress[progress.length - 1]}
                      />
                      <div className="date">
                        最后更新时间: {updatedAt.slice(0, 10)}
                      </div>
                    </div>
                    {/*<h5 className='deleteDoc' onClick={() => { handleDelete(doc, docTitle) }} style={{ zIndex: '8' }}><i className="fa-regular fa-trash-can"></i></h5>*/}
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
      <div className="docsSec">
        <h4>已完成</h4>
        <div className="docDivs">
          <>
            {docsCompleted.map(
              ({ docName, docType, progress, updatedAt, _id }, index) => (
                <div className="docDiv" key={index}>
                  <div
                    className="goToDoc"
                    onClick={() => {
                      handleLinktoResult(docType, _id);
                    }}
                  >
                    <div style={{ fontSize: "14px" }}>
                      {docType === "rcm" ? "推荐信" : ""}
                      {docType === "sop" ? "个人陈述" : ""}
                      {docType === "prp" ? "文书润色" : ""}
                    </div>
                    <div className="docTitle">
                      {docName ? docName : "未命名文件"}
                    </div>

                    <div className="date">
                      最后更新时间: {updatedAt.slice(0, 10)}
                    </div>
                  </div>
                  <button
                    className="optimization secondary_btn"
                    onClick={() => {
                      handleLinktoResult(docType, _id);
                    }}
                  >
                    优化
                  </button>
                  {/*<h5 className='deleteDoc' onClick={() => { handleDelete(doc, docTitle) }} style={{ zIndex: '8' }}><i className="fa-regular fa-trash-can"></i></h5>*/}
                </div>
              )
            )}
          </>
        </div>
        {/*<i className="fa-solid fa-spinner spin"></i>*/}
      </div>
      <div className="docsSec">
        <h4>润色额度</h4>
        <div
          className="quotaDiv"
          style={{ height: "140px", marginBottom: "24px" }}
        >
          <div>剩余润色额度</div>
          <span
            className={quota === 0 ? "grayText" : "colorText"}
            style={{ float: "right" }}
          >
            <span className="biggerText" style={{ color: "#6B92F7" }}>
              {quota}
            </span>
            <span>次</span>
          </span>
        </div>
      </div>
    </div>
  );
}
