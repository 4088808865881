import React from "react";
import star from "../assets/star.svg";
import star_half from "../assets/star_half.svg";
import star_empty from "../assets/star_empty.svg";

export default function StarRating({ tipScore }) {
  return (
    <div className="starRating">
      {Array.from({ length: Math.ceil(tipScore) }, (_, index) => (
        <div key={index} className="material-symbols-rounded">
          {index <= tipScore - 1 ? (
            <img alt="fullStar" className="fullStar" src={star} />
          ) : (
            <img alt="halfStar" className="halfStar" src={star_half} />
          )}
        </div>
      ))}
      {Array.from({ length: 5 - Math.ceil(tipScore) }, (_, index) => (
        <div key={index} className="material-symbols-rounded">
          {<img alt="emptyStar" className="emptyStar" src={star_empty} />}
        </div>
      ))}
    </div>
  );
}
