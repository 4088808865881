import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMyWritings, useAuth } from "../hooks/useContext";

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { docNumber } = useParams();

  const { auth } = useAuth();

  const {
    documents,
    payments,
    setLogIn,
    quota,
    setRequireUpdate,
    requireUpdate,
    runMyWritingsProvd,
    setRunMyWritingsProvd,
  } = useMyWritings();

  const [activeDoc, setActiveDoc] = useState(docNumber);

  useEffect(() => {
    setLogIn(true);
  }, [auth]);

  useEffect(() => {
    setActiveDoc(docNumber);
  }, [location]);

  const handleDocsLink = async (docType, docId, progress) => {
    setActiveDoc(docId);
    //console.log("require update:", runMyWritingsProvd);
    requireUpdate && setRunMyWritingsProvd(!runMyWritingsProvd);
    if (progress && progress[0] === "completed") {
      //if doc completed => go to result page
      navigate(`/_${docType}Result/${docId}/0`);
    } else {
      //if doc not-completed but paid => go to payment success page
      const paidDoc = payments.filter((pay) => {
        return pay.docId === docId;
      });
      if (paidDoc.length > 0) {
        if (docType === "rcm") navigate(`/3RcmPayment/${docId}?succeeded`);
        if (docType === "sop") navigate(`/5SopPayment/${docId}?succeeded`);
        if (docType === "prp") navigate(`/1PrpPayment/${docId}?succeeded`);
      } else {
        //if doc not-completed => go to form page
        navigate(`/0${docType}BasicForm/${docId}`);
      }
    }
  };

  const handleMainClick = (route) => {
    navigate(route);
    setActiveDoc();
  };

  return (
    <div className="sidebar">
      <div style={{ overflowY: "scroll", height: "670px" }}>
        <ul>
          <li
            className={"ddFundefined" === activeDoc ? "activeList" : "docList"}
            onClick={() => {
              handleMainClick("/addFile");
            }}
          >
            <span className="material-symbols-rounded">add</span>
            {`\u00A0\u00A0`}新增文书{" "}
          </li>
          <li
            className={"yWrundefined" === activeDoc ? "activeList" : "docList"}
            onClick={() => {
              handleMainClick("/myWritings");
            }}
          >
            <span className="material-symbols-rounded">dashboard</span>
            {`\u00A0\u00A0`}我的文书
          </li>
        </ul>
        <ul>
          {documents &&
            documents.filter((doc) => {
              return doc.docType === "rcm";
            }).length > 0 && (
              <div className="sidebarDocs">
                <div className="listTitle">推荐信</div>
                {documents.map(
                  ({ docType, docName, _id, progress }, index) =>
                    docType === "rcm" && (
                      <li
                        key={index}
                        className={_id === activeDoc ? "activeList" : "docList"}
                        onClick={() => {
                          handleDocsLink(docType, _id, progress);
                        }}
                      >
                        {progress[0] === "completed" ? (
                          <span className="material-symbols-rounded">task</span>
                        ) : (
                          <span className="material-symbols-rounded">
                            border_color
                          </span>
                        )}
                        {`\u00A0\u00A0`}
                        {docName ? docName : "未命名"}
                      </li>
                    )
                )}
              </div>
            )}
          {documents &&
            documents.filter((doc) => {
              return doc.docType === "sop";
            }).length > 0 && (
              <div className="sidebarDocs">
                <div className="listTitle">个人陈述</div>
                {documents.map(
                  ({ docType, docName, _id, progress }, index) =>
                    docType === "sop" && (
                      <li
                        key={index}
                        className={_id === activeDoc ? "activeList" : "docList"}
                        onClick={() => {
                          handleDocsLink(docType, _id, progress);
                        }}
                      >
                        {progress[0] === "completed" ? (
                          <span className="material-symbols-rounded">task</span>
                        ) : (
                          <span className="material-symbols-rounded">
                            border_color
                          </span>
                        )}
                        {`\u00A0\u00A0`}
                        {docName ? docName : "未命名"}
                      </li>
                    )
                )}
              </div>
            )}
          {documents &&
            documents.filter((doc) => {
              return doc.docType === "prp";
            }).length > 0 && (
              <div className="sidebarDocs">
                <div className="listTitle">文书润色</div>
                {documents.map(
                  ({ docType, docName, _id, progress }, index) =>
                    docType === "prp" && (
                      <li
                        key={index}
                        className={_id === activeDoc ? "activeList" : "docList"}
                        onClick={() => {
                          handleDocsLink(docType, _id, progress);
                        }}
                      >
                        {progress[0] === "completed" ? (
                          <span className="material-symbols-rounded">task</span>
                        ) : (
                          <span className="material-symbols-rounded">
                            border_color
                          </span>
                        )}
                        {`\u00A0\u00A0`}
                        {docName ? docName : "未命名"}
                      </li>
                    )
                )}
              </div>
            )}
        </ul>
      </div>
      <div className="sidebar_quota">
        剩余润色额度
        <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
          {" "}
          {quota}{" "}
        </span>
        次{" "}
      </div>
    </div>
  );
}
