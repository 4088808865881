import React, { useEffect } from "react";
import { useMyWritings, usePrice } from "../hooks/useContext";
import { Link, useNavigate } from "react-router-dom";

export default function History() {
  const navigate = useNavigate();

  const { payments, documents } = useMyWritings();
  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    prpOne,
    prpThree,
    prpFive,
    promotionAlldocs,
  } = usePrice();

  console.log();

  const docInfo =
    payments &&
    documents &&
    payments.map((payment) => {
      const [document] = documents.filter((doc) => {
        return doc._id === payment.docId;
      });
      console.log(document);
      return {
        docName: document?.docName,
        docType: document?.docType,
        date: payment.createdAt.slice(0, 10),
        amount: payment.amount,
      };
    });

  docInfo &&
    docInfo.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

  return (
    <div className="account">
      <h3 style={{ marginBottom: "50px" }}>付款纪录</h3>
      <div className="s-table-container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">服务项目</th>
              <th scope="col">文书名称</th>
              <th scope="col">付款日期</th>
              <th scope="col">金额</th>
            </tr>
          </thead>
          <tbody>
            {payments &&
              docInfo.map((doc, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {doc.amount === rcmPrice * promotionAlldocs && "推荐信"}
                      {doc.amount === sopPrice * promotionAlldocs && "个人陈述"}
                      {doc.amount === prpPrice * promotionAlldocs && "润色"}
                      {doc.amount === prpOne && `加价润色 X 1`}
                      {doc.amount === prpThree && `加价润色 X 3`}
                      {doc.amount === prpFive && `加价润色 X 5`}
                    </td>
                    <td style={{ width: "300px" }}>{doc.docName}</td>
                    <td> {doc.date}</td>
                    <td> {doc.amount}￥</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
