import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMyWritings, useFormCtn } from "../hooks/useContext";
import { usePrice } from "../hooks/useContext";
import axios from "axios";

export default function AddFile() {
  const navigate = useNavigate();

  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    promotionLable,
    lableContent,
    promotionAlldocs,
  } = usePrice();
  const { setErrMsg, runMyWritingsProvd, setRunMyWritingsProvd } =
    useMyWritings();
  const {
    setDocType,
    setFormCtn,
    setDocTitle,
    setProgress,
    setAllowAsisPopup,
  } = useFormCtn();

  const [isProcessing, setIsProcessing] = useState(false);

  const progressMap = new Map([
    ["rcm", [false, false, false, 4]],
    ["sop", [false, false, false, false, false, 6]],
    ["prp", [false, 2]],
  ]);

  const formMap = new Map([
    ["rcm", [[], [], []]],
    ["sop", [[], "", "", "", ""]],
    ["prp", [[]]],
  ]);

  const addNewFile = async (docType) => {
    setIsProcessing(true);
    //setFormCtn([]);
    //setDocType(docType);
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/documents`,
        data: {
          docType: docType,
          form: formMap.get(docType),
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        console.log("success", "lalalaDocument created successfully!");
        //console.log(res.data.data.document._id);
        navigate(`/0${docType}BasicForm/${res.data.data.document._id}`);
        setAllowAsisPopup(true);
        setRunMyWritingsProvd(!runMyWritingsProvd);
        setDocType(docType);
        setDocTitle("");
        setProgress(progressMap.get(docType));
        setFormCtn(formMap.get(docType));
      }
    } catch (err) {
      if (err) {
        console.log(err);
        setErrMsg(err?.response.data.message);
      }
    }
  };

  return (
    <div className="addFile">
      <h3>新增文书</h3>

      <p
        style={{
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: "#DB5461",
          textAlign: "center",
        }}
      >
        {/* 🎁 第一个文书享均一优惠价6.66元 🎁*/}{" "}
      </p>

      <div className="addDocTypes">
        <div className="addDocType">
          <h4>推荐信</h4>
          <p>
            适用于无初稿的申请人
            。根据用户填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、润色修改，以及文书终稿。
          </p>
          <p>约300-500单词 </p>
          <div
            className={!promotionLable ? "offscreen" : undefined}
            style={{
              backgroundColor: "#DB5461",
              color: "white",
              padding: "0 5px",
              width: "fit-content",
              marginBottom: "20px",
            }}
          >
            {lableContent}
          </div>
          <div></div>
          <span
            className="colorText"
            style={{ position: "absolute", bottom: "20px" }}
          >
            <span className={promotionLable ? "deletePrice" : "biggerText"}>
              ¥{rcmPrice}&nbsp;
            </span>
            <span className="redText">&nbsp;¥</span>
            <span className="biggerText">{rcmPrice * promotionAlldocs}</span>
          </span>
          <button
            style={{
              position: "absolute",
              bottom: "30px",
              right: "32px",
              paddingLeft: "15px",
            }}
            onClick={() => addNewFile("rcm")}
            disabled={isProcessing}
          >
            <i className="material-symbols-rounded">add</i> 新增
          </button>
        </div>
        <div className="addDocType">
          <h4>个人陈述</h4>
          <p>
            适用于无初稿的申请人
            。根据用户填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、润色修改，以及文书终稿。
          </p>
          <p>约800-1000单词</p>
          <div
            className={!promotionLable ? "offscreen" : undefined}
            style={{
              backgroundColor: "#DB5461",
              color: "white",
              padding: "0 5px",
              width: "fit-content",
              marginBottom: "20px",
            }}
          >
            {lableContent}
          </div>
          <div></div>
          <span
            className="colorText"
            style={{ position: "absolute", bottom: "20px" }}
          >
            <span className={promotionLable ? "deletePrice" : "biggerText"}>
              ¥{sopPrice}&nbsp;
            </span>
            <span className="redText">&nbsp;¥</span>
            <span className="biggerText">{sopPrice * promotionAlldocs}</span>
          </span>
          <button
            style={{
              position: "absolute",
              bottom: "30px",
              right: "32px",
              paddingLeft: "15px",
            }}
            onClick={() => addNewFile("sop")}
            disabled={isProcessing}
          >
            <i className="material-symbols-rounded">add</i> 新增
          </button>
        </div>
        <div className="addDocType">
          <h4>文书润色</h4>
          <p>
            适用于有英文初稿、需要语言/拼写/行文逻辑修改的申请人。对文书进行语句修正、结构调整。
          </p>
          <p>
            推荐信500单词內
            <br />
            个人陈述1000单词內
          </p>
          <div
            className={!promotionLable ? "offscreen" : undefined}
            style={{
              backgroundColor: "#DB5461",
              color: "white",
              padding: "0 5px",
              width: "fit-content",
              marginBottom: "20px",
            }}
          >
            {lableContent}
          </div>
          <div></div>
          <span
            className="colorText"
            style={{ position: "absolute", bottom: "20px" }}
          >
            <span className={promotionLable ? "deletePrice" : "biggerText"}>
              ¥{prpPrice}&nbsp;
            </span>
            <span className="redText">&nbsp;¥</span>
            <span className="biggerText">{prpPrice * promotionAlldocs}</span>
          </span>
          <button
            style={{
              position: "absolute",
              bottom: "30px",
              right: "32px",
              paddingLeft: "15px",
            }}
            onClick={() => addNewFile("prp")}
            disabled={isProcessing}
          >
            <i className="material-symbols-rounded">add</i> 新增
          </button>
        </div>
      </div>
    </div>
  );
}

//<img className="promo" src={promo} />
