import React from "react";
import QRcode from "../assets/QRcode.JPG";

const AddChat = ({ handleClose }) => {
  const popUpBoxStyles = {
    position: "fixed",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
    justifyContent: "center",
    alignItems: "center",
  };
  const boxStyles = {
    width: "30vw",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "center",
    letterSpacing: "3px",
  };
  const titleStyles = {
    fontSize: "20px",
    marginBottom: "15px",
  };

  return (
    <div>
      <div className="Popup" style={popUpBoxStyles} onClick={handleClose}>
        <div className="box" style={boxStyles}>
          <div style={titleStyles}>扫码咨询线上客服</div>
          <div>
            <img
              style={{ width: "210px" }}
              src={QRcode}
              alt="consultant-QRcode"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddChat;
