import React from "react";
import { useMyWritings } from "../hooks/useContext";
import GenerateWriting from "./GenerateWriting";

export default function UseQuota() {
  const { quotaUsed } = useMyWritings();

  console.log(quotaUsed);
  return (
    <div className="s-table-container" style={{ margin: "10px 0px 30px 0px" }}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">服务项目</th>
            <th scope="col">金额</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>润色</td>
            <td>使用润色额度 / 0￥</td>
          </tr>
        </tbody>
      </table>
      <GenerateWriting style={"float_btn "} quotaUsed={quotaUsed} />
    </div>
  );
}
