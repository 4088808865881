import React from "react";
import { useResult, useFormCtn, useMyWritings } from "../hooks/useContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Paraphrase({
  disable,
  docType,
  writing,
  setWriting,
  setTips,
  float,
  useQuota,
}) {
  const navigate = useNavigate();

  const { quotaUsed } = useMyWritings();
  const { result, setResult, postOpenaiRating, prpType, setErrMsgPrp } =
    useResult();
  const { docId } = useFormCtn();

  const paraphrase = async () => {
    const paraphrase = async () => {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/openai_prp`,
          data: {
            draft: writing,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.paraphrasedWriting);
          let paraphrase = res.data.paraphrasedWriting;
          setResult((current) => [...current.slice(0, -1), [paraphrase, ""]]);
          if (docType === "prp") {
            //console.log(prpType);
            postOpenaiRating(paraphrase, prpType);
          } else {
            postOpenaiRating(paraphrase, docType);
          }
          useQuota && quotaUsed();
        }
      } catch (err) {
        console.log(
          "Failed to fetch openai for paraphrase!",
          err.response.data.message
        );
        setErrMsgPrp("连接失败，请稍后再尝试一次。");
      }
    };

    navigate(`/_${docType}Result/${docId}/${result.length}`);
    setResult([...result, []]);
    paraphrase();

    // if (docType === "prp") {
    //   const params = {
    //     writing: writing,
    //     docType: prpType,
    //   };

    //   navigate(`/_${docType}Result/${docNumber}/${result.length}`);
    //   setResult([...result, []]);

    //   await Parse.Cloud.run("postOpenaiParaphrase", params)
    //     .then((resp) => {
    //       console.log("postOpenaiParaphrase run");
    //       let paraphrase = resp?.choices[0].message.content;
    //       paraphrase &&
    //         setResult((current) => [...current.slice(0, -1), [paraphrase, ""]]);
    //       postOpenaiRating(paraphrase, prpType);
    //       useQuota && quotaUsed();
    //     })
    //     .catch((error) => {
    //       console.log("Failed to fetch openai for paraphrase!", error);
    //       setErrMsgPrp("连接失败，请稍后再尝试一次。");
    //     });
    // } else {
    //   const params = {
    //     writing: writing,
    //     docType: docType,
    //   };

    //   navigate(`/_${docType}Result/${docNumber}/${result.length}`);
    //   setResult([...result, []]);

    //   await Parse.Cloud.run("postOpenaiParaphrase", params)
    //     .then((resp) => {
    //       console.log("postOpenaiParaphrase run");
    //       let paraphrase = resp?.choices[0].message.content;
    //       paraphrase &&
    //         setResult((current) => [...current.slice(0, -1), [paraphrase, ""]]);
    //       postOpenaiRating(paraphrase, docType);
    //       useQuota && quotaUsed();
    //     })
    //     .catch((error) => {
    //       console.log("Failed to fetch openai for paraphrase!", error);
    //       setErrMsgPrp("连接失败，请稍后再尝试一次。");
    //     });
    // }
  };

  return (
    <button
      className={float ? `float_btn` : ""}
      disabled={disable}
      onClick={paraphrase}
      style={{
        boxShadow:
          " 0px 1px 2px 1px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.1)",
        marginTop: "30px",
        marginBottom: "20px",
      }}
    >
      提交润色修改
    </button>
  );
}
