import React, { useState, useEffect } from "react";
import Stripe from "./Stripe";
import { useFormCtn, useMyWritings } from "../hooks/useContext";
import { useLocation, useParams } from "react-router-dom";

export default function PaymentPlus() {
  const location = useLocation();
  const plan = location.state?.plan;
  const { complete, formCtn, docType } = useFormCtn();

  //console.log(plan);
  return (
    <>
      <div className="formStyle" style={{ marginTop: "-180px" }}>
        <Stripe docType={docType} plan={plan} />
      </div>
    </>
  );
}
