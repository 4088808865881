import React, { useEffect } from "react";
import { createContext, useState } from "react";
import axios from "axios";

const MyWritingsContext = createContext({});

export const MyWritingsProvider = ({ children }) => {
  const [logIn, setLogIn] = useState();
  const [requireUpdate, setRequireUpdate] = useState(false);
  const [runMyWritingsProvd, setRunMyWritingsProvd] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [user, setUser] = useState();
  const [payments, setPayments] = useState();
  const [quota, setQuota] = useState();
  const [firstTime, setFirstTime] = useState(false);

  const [email, setEmail] = useState();
  const [wechat, setWechat] = useState();
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/user`,
          withCredentials: true,
        });
        if (res.data.status === "success") {
          setDocuments(res.data.data.user.documents);
          setUser(res.data.data.user.name);
          setEmail(res.data.data.user.email);
          setWechat(res.data.data.user.wechat);
          setPayments(res.data.data.user.payments);
          res.data.data.user?.quota
            ? setQuota(res.data.data.user.quota)
            : setQuota(0);
          //console.log(documents.length === 0);
          user && documents && documents.length === 0 && setFirstTime(true);
        }
      } catch (err) {
        console.log(err);
        alert("error", "Error log in! Try again.");
      }
    };
    logIn && getUserInfo();
  }, [logIn, runMyWritingsProvd]);

  const quotaUsed = async () => {
    //console.log("run quotaUsed", quota);

    try {
      const res = await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}api/v1/user/updateMe`,
        data: {
          quota: quota - 1,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        let newQota = res.data.data.user.quota;
        setQuota(newQota);
        //console.log(res.data.data.user.quota, "quota used");
      }
    } catch (err) {
      console.log(
        "Failed to fetch openai for using quota!",
        err.response.data.message
      );
      setErrMsg("连接失败，请稍后再尝试一次。");
    }
  };

  return (
    <MyWritingsContext.Provider
      value={{
        logIn,
        setLogIn,
        documents,
        setDocuments,
        user,
        setUser,
        payments,
        setPayments,
        quota,
        setQuota,
        errMsg,
        setErrMsg,
        firstTime,
        setFirstTime,
        quotaUsed,
        email,
        setEmail,
        wechat,
        setWechat,
        runMyWritingsProvd,
        setRunMyWritingsProvd,
        requireUpdate,
        setRequireUpdate,
      }}
    >
      {children}
    </MyWritingsContext.Provider>
  );
};

export default MyWritingsContext;
