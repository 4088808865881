import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function PlusPopup({ handleClose, writing }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [plan, setPlan] = useState();

  const popUpBoxStyles = {
    position: "fixed",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
    justifyContent: "center",
    alignItems: "center",
  };

  const closeArea = {
    position: "fixed",
    display: "flex",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
  };

  const boxStyles = {
    width: "420px",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "left",
    zIndex: "10",
  };

  const divStyles = {
    width: "31%",
    backgroundColor: "#F1F5F9",
    padding: "10px",
    overflow: "auto",
    textAlign: "left",
    marginBottom: "20px",
    fontSize: "14px",
    borderRadius: "8px",
  };

  const inputStyles = {
    width: "22px",
    minWidth: "22px",
    height: "18px",
    marginBottom: "15px",
  };

  const spanStyles = {
    fontSize: "30px",
    fontFamily: "PingFangSC-Medium",
  };

  const goToPay = () => {
    navigate(
      location.pathname.slice(0, 11) + "Payment" + location.pathname.slice(11),
      { state: { plan: plan, writing: writing } }
    );
  };

  return (
    <div style={popUpBoxStyles}>
      <div style={closeArea} onClick={handleClose}></div>
      <div className="plus" style={boxStyles}>
        <div
          style={{
            fontFamily: "PingFangSC-Regular",
            fontSize: "20px",
            marginBottom: "10px",
          }}
        >
          选择加价润色方案
        </div>
        <p style={{ padding: "5px 0 0 0", marginBottom: "5px" }}>
          已修改完文书了吗？
          <br />
          请先修改文书，再付款后即可看到新版文书。选择加价润色方案套餐，可在不同文书上使用。
        </p>

        <div
          value={plan}
          onChange={(e) => setPlan(e.target.value)}
          style={{ display: "flex", gap: "20px" }}
        >
          <label htmlFor="one" style={divStyles}>
            <div>
              <input
                style={inputStyles}
                type="radio"
                value={1}
                name="plan"
                id="one"
              />
              <br />
              <span style={{ fontFamily: "PingFangSC-Medium" }}>¥</span>
              <span style={spanStyles}>9</span>/1次
            </div>
          </label>
          <label htmlFor="three" style={divStyles}>
            <div>
              <input
                style={inputStyles}
                type="radio"
                value={3}
                name="plan"
                id="three"
              />
              <br />
              <span style={{ fontFamily: "PingFangSC-Medium" }}>¥</span>
              <span style={spanStyles}>19</span>/3次
            </div>
          </label>
          <label htmlFor="five" style={divStyles}>
            <div>
              <input
                style={inputStyles}
                type="radio"
                value={5}
                name="plan"
                id="five"
              />
              <br />
              <span style={{ fontFamily: "PingFangSC-Medium" }}>¥</span>
              <span style={spanStyles}>29</span>/5次
            </div>
          </label>
        </div>

        <div style={{ float: "right" }}>
          <button className="secondary_btn" onClick={handleClose}>
            取消
          </button>
          <button
            style={{ marginLeft: "10px" }}
            disabled={plan ? false : true}
            onClick={goToPay}
          >
            付款
          </button>
        </div>
      </div>
    </div>
  );
}
