import "./App.css";
import Register from "./Register";
import Login from "./Login";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./infoPages/Home";
import RequireAuth from "./RequireAuth";
import LayoutSidebar from "./Layout/LayoutSidebar";
import LayoutForm from "./Layout/LayoutForm";
import RcmBasicForm from "./authPages/rcmPages/RcmBasicForm";
import SopBasicForm from "./authPages/sopPages/SopBasicForm";
import RcmRcmdForm from "./authPages/rcmPages/RcmRcmdForm";
import RcmCntForm from "./authPages/rcmPages/RcmCntForm";
import RcmInfo from "./infoPages/RcmInfo";
import SopInfo from "./infoPages/SopInfo";
import AddFile from "./authPages/AddFile";
import Payment from "./authPages/Payment";
import SopBckgrndForm from "./authPages/sopPages/SopBckgrndForm";
import SopExprnForm from "./authPages/sopPages/SopExprnForm";
import SopGraduForm from "./authPages/sopPages/SopGraduForm";
import SopCharctForm from "./authPages/sopPages/SopCharctForm";
import PrpForm from "./authPages/prpPages/PrpForm";
import MyWritings from "./authPages/MyWritings";
import Result from "./authPages/Result";
import Account from "./authPages/Account";
import Asistant from "./authPages/Asistant";
import History from "./authPages/History";
import FormContent from "./authPages/FormContent";
import PrpInfo from "./infoPages/PrpInfo";
import PaymentPlus from "./authPages/PaymentPlus";
import ErrorPage from "./infoPages/ErrorPage";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import Writers from "./authPages/Writers";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/rcmInfo" element={<RcmInfo />} />
        <Route path="/sopInfo" element={<SopInfo />} />
        <Route path="/prpInfo" element={<PrpInfo />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />

        {/* auth routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<LayoutSidebar />}>
            <Route path="/addFile" element={<AddFile />} />
            <Route path="/myWritings" element={<MyWritings />} />

            <Route path="/asistant" element={<Asistant />} />

            <Route path="/account" element={<Account />} />
            <Route path="/history" element={<History />} />
            <Route path="/writers-list" element={<Writers />} />

            <Route path="/" element={<LayoutForm />}>
              <Route
                path="0rcmBasicForm/:docNumber"
                element={<RcmBasicForm />}
              />
              <Route path="1rcmRcmdForm/:docNumber" element={<RcmRcmdForm />} />
              <Route path="2rcmCntForm/:docNumber" element={<RcmCntForm />} />
              <Route path="3rcmPayment/:docNumber" element={<Payment />} />

              <Route
                path="0sopBasicForm/:docNumber"
                element={<SopBasicForm />}
              />
              <Route
                path="1sopBckgrndForm/:docNumber"
                element={<SopBckgrndForm />}
              />
              <Route
                path="2sopExprnForm/:docNumber"
                element={<SopExprnForm />}
              />
              <Route
                path="3sopCharctForm/:docNumber"
                element={<SopCharctForm />}
              />
              <Route
                path="4sopGraduForm/:docNumber"
                element={<SopGraduForm />}
              />
              <Route path="5sopPayment/:docNumber" element={<Payment />} />

              <Route path="0prpBasicForm/:docNumber" element={<PrpForm />} />
              <Route path="1prpPayment/:docNumber" element={<Payment />} />
            </Route>

            <Route
              path="/_rcmResult/:docNumber/:version"
              element={<Result />}
            />
            <Route
              path="/_sopResult/:docNumber/:version"
              element={<Result />}
            />
            <Route
              path="/_prpResult/:docNumber/:version"
              element={<Result />}
            />

            <Route
              path="/_RcmformContent/:docNumber"
              element={<FormContent />}
            />
            <Route
              path="/_SopformContent/:docNumber"
              element={<FormContent />}
            />
            <Route
              path="/_PrpformContent/:docNumber"
              element={<FormContent />}
            />

            <Route
              path="/_RcmResultPayment/:docNumber/:version"
              element={<PaymentPlus />}
            />
            <Route
              path="/_SopResultPayment/:docNumber/:version"
              element={<PaymentPlus />}
            />
            <Route
              path="/_PrpResultPayment/:docNumber/:version"
              element={<PaymentPlus />}
            />
          </Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}

export default App;
