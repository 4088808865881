import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { useFormCtn, useMyWritings } from "../hooks/useContext";
import axios from "axios";

const ResultContext = createContext({});

export const ResultProvider = ({ children }) => {
  const { documents, logIn } = useMyWritings();
  const { prpType, docId } = useFormCtn();

  const [result, setResult] = useState([]);
  const [docTitle, setDocTitle] = useState("");
  const [version, setVersion] = useState();

  const [runGetResult, setrRunGetResult] = useState(false);
  const [reloadResult, setReloadResult] = useState(false);
  ////////////////////////////////
  const [errMsg, setErrMsg] = useState(""); //for Payment & Result
  const [errMsgPrp, setErrMsgPrp] = useState(""); //for Paraphrase

  /*
        useEffect(() => {
            setrRunGetResult(!runGetResult);
        }, [])*/

  useEffect(() => {
    const getDocResultAxios = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          withCredentials: true,
        });
        if (res.data.status === "success") {
          setResult(res.data.data.doc.result);
          setDocTitle(res.data.data.doc.docName);
          //console.log(res.data.data.doc.docName);
          console.log("get result by axios");
        }
      } catch (err) {
        console.log(err.response.data.message);
        alert("error", "Error log in! Try again.");
      }
    };

    const getDocResult = () => {
      const document = documents.filter((doc) => {
        return doc._id === docId;
      });
      //console.log(document);
      if (document.length === 0) {
        getDocResultAxios();
      } else {
        //console.log("result:" + document[0].result);
        setDocTitle(document[0].docName);
        setResult(document[0].result);
        console.log("get result");
      }
    };

    if (documents.length > 0 && docId) {
      getDocResult();
    } else {
      docId && getDocResultAxios();
    }
  }, [runGetResult, docId]);

  // useEffect(() => {
  //     if (!logIn) {
  //         setResult([]);
  //         setResultID('');
  //     }
  // }, [logIn]);

  const saveResult = async (outcome) => {
    if (outcome) {
      //console.log(result);
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            result: [...result, outcome],
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc.result);
          setResult((current) => [...current.slice(0, -1), outcome]);
          console.log("result saved");
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    }
  };

  const postOpenaiRating = async function (writing, type) {
    if (!type) type = prpType;
    //console.log(prpType);
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/${type}_rating`,
        data: {
          writing: writing,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data.rating);
        const tips = res.data.rating;
        tips && saveResult([writing, tips]);
      }
    } catch (error) {
      console.error("Failed to fetch openai for rating!", error);
      setErrMsg("生成失败，请回到“我的文书”重试。");
    }
  };

  return (
    <ResultContext.Provider
      value={{
        result,
        setResult,
        runGetResult,
        setrRunGetResult,
        reloadResult,
        setReloadResult,
        saveResult,
        postOpenaiRating,
        version,
        setVersion,
        docTitle,
        setDocTitle,
        errMsg,
        setErrMsg,
        errMsgPrp,
        setErrMsgPrp,
      }}
    >
      {children}
    </ResultContext.Provider>
  );
};

export default ResultContext;
