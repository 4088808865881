import React from 'react'

export default function ErrMessage(props) {

    const popUpBoxStyles = {
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        left: '0',
        top: ' 0',
        width: '100vw',
        height: '100vh',
        zIndex: '10',
    }

    const boxStyles = {
        backgroundColor: '#FFFFFF',
        position: 'fixed',
        top: '35vh',
        left: '35vw',
        width: '30vw',
        backgroundColor: '#fff',
        padding: '20px 30px 30px 30px',
        overflow: 'auto',
        textAlign: 'left',
        letterSpacing: '3px',
        border: 'solid 1px lightgray',
        zIndex: '10',
        wordWrap: 'break-word',
        borderRadius: '6px',

    }
    const buttonStyles = { border: 'solid 1px #CBD5E1', borderRadius: '10em/10em', backgroundColor: '#FFFFFF', color: '#475569', float: 'right' }


    return (

        <div className="Popup" style={popUpBoxStyles} onClick={props.handleClose} >
            <div className="box" style={boxStyles}>
                <div> 🚧 {props.message}</div>
                <button onClick={props.handleClose} style={buttonStyles}>关闭</button>
            </div>
        </div>

    )
}
