import React from 'react'
import { Outlet } from "react-router-dom"
import Tabs from './Tabs'



export default function LayoutForm() {
    return (
        <div>
            <Tabs />
            <main className="App">
                <Outlet />
            </main>
        </div>
    )
}
