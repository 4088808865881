import React from 'react';
import jsPDF from "jspdf";

export default function DownloadWriting({ writing, docTitle }) {

    const download = async () => {
        const doc = new jsPDF({ orientation: "p", unit: "mm", format: "a4", lineHeight: 1.5 });
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()


        const changeLine = doc.splitTextToSize(writing, pageWidth + 40);
        doc.setFont("times", "italic");
        doc.setFontSize(12);


        for (let i = 0; i < Math.ceil(changeLine.length / 34); i++) {
            if (i !== 0) {
                doc.addPage();
                doc.text(changeLine.slice(34 * i, 34 * (i + 1)), 20, 75);
            } else {
                doc.text(changeLine.slice(34 * i, 34 * (i + 1)), 20, 55);
            };
            doc.setLineWidth(0.15);
            doc.line(20, 50, pageWidth - 20, 50)
            doc.line(20, pageHeight - 20, pageWidth - 20, pageHeight - 20)
        };
        doc.save(`${docTitle}.pdf`);
    };


    return (
        <div>
            <button className="DownloadWriting" onClick={download} style={{ backgroundColor: '#FFFFFF', color: '#475569', float: 'right', padding: 0 }}><span className="material-symbols-rounded"> download</span>下载pdf</button>
            <div style={{ height: '100px' }}></div>
        </div>
    )
}
