import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMyWritings } from "../hooks/useContext";
import axios from "axios";

const FormContext = createContext({});

export const FormCntProvider = ({ children }) => {
  const { documents } = useMyWritings();

  const [docId, setDocId] = useState();
  const [formCtn, setFormCtn] = useState([]);
  const [docType, setDocType] = useState();
  const [progress, setProgress] = useState();
  const [docTitle, setDocTitle] = useState("");
  const [titleChanged, setTitleChanged] = useState(false);
  const [typedTitle, setTypedTitle] = useState("");
  const [prpType, setPrpType] = useState("");

  const [runGetCtn, setRunGetCtn] = useState(false);
  const [allowAsisPopup, setAllowAsisPopup] = useState(false);

  // useEffect(() => {
  //   if (docNumber) {
  //     setFormCtn([]);
  //     setDocId(docNumber);
  //     console.log("set doc id");
  //   } else {
  //     setFormCtn([]);
  //     setDocId();
  //   }
  // }, [runGetCtn]);

  // useEffect(() => {
  //   setFormCtn([]);
  //   setDocTitle("");
  //   console.log("docNumber:" + docNumber);
  //   docNumber && setDocId(docNumber);
  // }, [runGetCtn]);

  useEffect(() => {
    const getDocFormAxios = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          withCredentials: true,
        });
        if (res.data.status === "success") {
          setFormCtn(res.data.data.doc.form);
          setDocTitle(res.data.data.doc.docName);
          setDocType(res.data.data.doc.docType);
          setProgress(res.data.data.doc.progress);
          //console.log(res.data.data.doc.docName);
          console.log("get form ctn by axios");
        }
      } catch (err) {
        console.log(err.response.data.message);
        alert("error", "Error log in! Try again.");
      }
    };

    const getDocForm = () => {
      const document = documents.filter((doc) => {
        return doc._id === docId;
      });
      //console.log(document);
      if (document.length === 0) {
        getDocFormAxios();
      } else {
        //console.log("formCtn:" + document[0].form);
        setDocTitle(document[0].docName);
        setDocType(document[0].docType);
        setProgress(document[0].progress);
        setFormCtn(document[0].form);
        console.log("get form ctn");
      }
    };

    if (documents.length > 0 && docId) {
      //console.log(documents);
      getDocForm();
    } else {
      docId && getDocFormAxios();
    }
  }, [runGetCtn, docId]);

  useEffect(() => {
    docType === "prp" && formCtn && setPrpType(formCtn[0][0]);
    //console.log(prpType);
  }, [formCtn]);

  // useEffect(() => {
  //     setRun(!run);
  // }, [])

  // useEffect(() => {
  //   setComplete([]);
  // }, [docNumber]);

  // useEffect(() => {
  //   setFormCtn([]);
  //   setComplete([]);
  //   setDocTitle("");
  // }, [docType, run]);

  return (
    <FormContext.Provider
      value={{
        docId,
        setDocId,
        docType,
        setDocType,
        progress,
        setProgress,
        formCtn,
        setFormCtn,
        titleChanged,
        setTitleChanged,
        typedTitle,
        setTypedTitle,
        docTitle,
        setDocTitle,
        prpType,
        setPrpType,
        runGetCtn,
        setRunGetCtn,
        allowAsisPopup,
        setAllowAsisPopup,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
