import React from "react";
import { useFormCtn } from "../hooks/useContext";
import { useNavigate } from "react-router-dom";

export default function FormContent() {
  const navigate = useNavigate();

  const { docTitle, docType, formCtn } = useFormCtn();

  const RcmTitle = ["基本信息", "推荐人资料", "推荐信内容"];
  const RcmContent = [
    [
      "姓名 (拼音)",
      "性别代名词 (Pronoun)",
      "申请学位和专业 (Program)",
      "申请学校 (University)",
    ],
    ["推荐人姓名 (拼音)", "职称 (Position)", "任职机构", "任职专业"],
    ["推荐人和你的关系", "希望推荐人如何推荐你", "推荐人的整体评价"],
  ];

  const SopTitle = [
    "基本信息",
    "学习背景",
    "實習/工作经验",
    "学校专业特色",
    "毕业后的规划",
  ];
  const SopContent = [
    [
      "目前就讀學校/工作單位",
      "目前就讀專業/職位",
      "申请学位和专业 (Program)",
      "申请学校 (University)",
    ],
    ["学习背景，相关课程经验"],
    ["相关课外活动/实习/工作 经验"],
    ["学校专业特色"],
    ["毕业后的规划"],
  ];

  const typeMap = new Map([
    ["rcm", [RcmTitle, RcmContent]],
    ["sop", [SopTitle, SopContent]],
  ]);

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <div className="result">
      <h3 className="title" style={{ flex: "auto" }}>
        {docTitle}
      </h3>
      <button className="secondary_btn" onClick={handleOnClick}>
        <span className="material-symbols-rounded">undo</span> 返回英文文书
      </button>
      <div>
        {docType && docType === "rcm"
          ? typeMap.get(docType)[0].map((title, index) => (
              <div className="formTitle" key={index}>
                <h3>{title}</h3>
                <div>
                  {docType &&
                    formCtn.length > 0 &&
                    typeMap.get(docType)[1][index].map((topic, i) => (
                      <div key={i} className="formCntRow">
                        {" "}
                        <div className="cntTitle"> {topic} </div>{" "}
                        <div className="formCnt"> {formCtn[index][i]} </div>
                      </div>
                    ))}
                </div>
              </div>
            ))
          : typeMap.get(docType)[0].map((title, index) =>
              index < 1 ? (
                <div className="formTitle" key={index}>
                  <h3>{title}</h3>
                  <div>
                    {docType &&
                      formCtn.length > 0 &&
                      typeMap.get(docType)[1][index].map((topic, i) => (
                        <div key={i} className="formCntRow">
                          {" "}
                          <div className="cntTitle"> {topic} </div>{" "}
                          <div className="formCnt"> {formCtn[index][i]} </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="formTitle" key={index}>
                  <h3>{title}</h3>
                  <div>
                    {docType &&
                      formCtn.length > 0 &&
                      typeMap.get(docType)[1][index].map((topic, i) => (
                        <div key={i} className="formCntRow">
                          {" "}
                          <div className="cntTitle"> {topic} </div>{" "}
                          <div className="formCnt"> {formCtn[index]} </div>
                        </div>
                      ))}
                  </div>
                </div>
              )
            )}
      </div>
    </div>
  );
}
