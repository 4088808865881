import React, { useState } from "react";
import ErrMessage from "../components/ErrMessage";
import Skeleton from "../components/Skeleton";
import freeExp from "../assets/freeExp.svg";
import axios from "axios";
import beanbean from "../assets/beanbean.svg";

const initialValues = {
  program: "",
  university: "",
  programIntro: "",
  universityIntro: "",
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function Asistant() {
  const [outcome, setOutcome] = useState();
  const [errMsg, setErrMsg] = useState("");

  const [asisForm, setAsisForm] = useState(initialValues);
  // const [program, setProgram] = useState();
  // const [university, setUniversity] = useState();
  // const [programIntro, setProgramIntro] = useState();
  // const [universityIntro, setUniversityIntro] = useState();
  const [count, setCount] = useState(0);
  const [showOutcome, setShowOutcome] = useState(false);

  const operateAgain = async () => {
    setShowOutcome(false);
    setAsisForm({
      program: "",
      university: "",
      programIntro: "",
      universityIntro: "",
    });
    await delay(10000);
    setOutcome("");
  };

  const handleCopy = async () => {
    //console.log(outcome);
    await navigator.clipboard.writeText(outcome);
    alert("文书已复制到剪切板");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowOutcome(true);

    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/aiApi/asistant`,
        data: asisForm,
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data.asistantContent);
        let outcome = res.data.asistantContent;
        setOutcome(outcome);
      }
    } catch (error) {
      console.error("Error while running asistant", error);
      setErrMsg("连线失败,请再试一次");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAsisForm({
      ...asisForm,
      [name]: value,
    });
    //name === "programIntro" && setCount(value.length);
  };

  return (
    <div className="asistant">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <div className="rows">
        <div className={showOutcome ? "screenResult" : "screen"}>
          <form autoComplete="on" onSubmit={handleSubmit}>
            <div className="rows">
              <div>
                <dd className="subtitle">申请学位和专业 (Program)</dd>
                <input
                  type="text"
                  name="program"
                  value={asisForm.program}
                  onChange={handleInputChange}
                  required
                  placeholder="示例：MSc in Information Science"
                ></input>
              </div>
              <div>
                <dd className="subtitle">申请学校 (University)</dd>
                <input
                  type="text"
                  name="university"
                  value={asisForm.university}
                  onChange={handleInputChange}
                  required
                  placeholder="示例：Stanford University"
                ></input>
              </div>
            </div>
            <div>
              <dd className="subtitle">申请专业介紹</dd>
              <textarea
                type="text"
                name="programIntro"
                value={asisForm.programIntro}
                onChange={handleInputChange}
                required
                placeholder="从学校官网复制粘贴英文内容..."
                style={{ height: "260px" }}
              ></textarea>
            </div>
            <div>
              <dd className="subtitle">申请学校介紹</dd>
              <textarea
                type="text"
                name="universityIntro"
                value={asisForm.universityIntro}
                onChange={handleInputChange}
                required
                placeholder="从学校官网复制粘贴英文内容..."
                style={{ height: "200px" }}
              ></textarea>
            </div>
            <div>
              <button
                disabled={
                  asisForm.program &&
                  asisForm.university &&
                  asisForm.programIntro &&
                  asisForm.universityIntro
                    ? false
                    : true
                }
                style={{ float: "right" }}
              >
                开始分析
              </button>
            </div>
          </form>

          <div className={showOutcome ? "" : "offscreen"}>
            <div>
              {outcome ? (
                <textarea
                  type="text"
                  name="draft"
                  value={outcome}
                  onChange={() => setOutcome(outcome)}
                  required
                  style={{ width: "52vw", height: "600px" }}
                ></textarea>
              ) : (
                <div style={{ height: "600px" }}>
                  <div style={{ marginBottom: "30px", color: "#475569" }}>
                    <span className="material-symbols-outlined">smart_toy</span>
                    正在分析...
                  </div>
                  <Skeleton />
                </div>
              )}
            </div>
            <div style={{ float: "right" }}>
              <button onClick={handleCopy}>复制内容</button>
              <button
                style={{
                  backgroundColor: "white",
                  color: "#6B92F7",
                  marginLeft: "20px",
                  fontWeight: "600",
                }}
                onClick={operateAgain}
              >
                <span className="material-symbols-outlined">cached</span>
                {`\u00A0\u00A0`}再填一次
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(360, 360, 360)",
            position: "fixed",
            right: "0px",
            height: "calc(100vh - 70px)",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(170, 125, 206, 0.12)",
              width: "350px",
              maxWidth: "24vw",
              minWidth: "320px",
              maxHeight: "fitContent",
              height: "maxContent",
              color: "#6B92F7",
              fontWeight: "600",
              marginRight: "40px",
            }}
          >
            <img
              className="free"
              src={freeExp}
              alt="free-to-analyze-university's-features"
              style={{ position: "absolute", top: "20px", right: "70px" }}
            />
            <div className="rows" style={{ gap: "16px", padding: "40px 24px" }}>
              <img
                alt="出国留学申请"
                className="beanbean"
                style={{ width: "55px", marginTop: "-55px" }}
                src={beanbean}
              />
              {/*<span
                className="material-symbols-outlined"
                style={{ fontSize: "60px" }}
              >
                smart_toy
          </span>*/}
              {showOutcome ? (
                <div>
                  小豆帮帮分析了申请学校和专业特色，你可以复制或下载左侧内容。根据分析结果，你可以在文书中强调与之相符的个人经历，展现自己的专业热情，让文书更有说服力。
                </div>
              ) : (
                <div>
                  <div>
                    欢迎来到小豆帮帮！帮您整理申请学校和专业特色，总结在文书中可以强调的重点。
                  </div>
                  <ul
                    style={{
                      color: "#475569",
                      fontWeight: "300",
                      marginTop: "16px",
                    }}
                  >
                    <li style={{ listStyle: "disc inside" }}>分析学校特色</li>
                    <li style={{ listStyle: "disc inside" }}>挖掘专业要求</li>
                    <li style={{ listStyle: "disc inside" }}>提供文书建议</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*<button onClick={postOpenaiAsistant}>run stream</button>*/}
    </div>
  );
}

//<div style={{ backgroundColor: "rgba(170, 125, 206, 0.12)", width: "300px", height: "300px" }}></div>
