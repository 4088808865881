import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "./infoPages/Footer";
import axios from "axios";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default function Login() {
  const [sentEmail, setSentEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [message, setMessage] = useState("");

  const [loadReset, setLoadReset] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(sentEmail));
  }, [sentEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setLoadReset(true);
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/v1/user/forgotPassword`,
        data: {
          email: sentEmail,
          windowLocation: window.location.host,
        },
        withCredentials: true,
      });
      if (res.data.status === "success") {
        //console.log(res.data.message);
        setMessage("已传送重设密码链接至您輸入的郵箱");
      }
    } catch (error) {
      if (
        error.response.data.message === "There is no user with email address"
      ) {
        setMessage("此电子邮件地址没有对应的使用者。");
      } else {
        setMessage("传送连接失败,请再试一次");
      }
    }
  };

  return (
    <div className="infoP">
      <div className="loginPage">
        <div className="login">
          <h1>忘记密码</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email邮箱*</label>
            <input
              type="email"
              id="sentEmail"
              onChange={(e) => setSentEmail(e.target.value)}
              value={sentEmail}
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="emailnote"
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
            />
            <p
              id="emailnote"
              className={
                emailFocus && sentEmail && !validEmail
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              邮箱格式不正确
            </p>
            {message ? (
              <div>{message}</div>
            ) : (
              <button disabled={loadReset}>
                {loadReset ? "正在传送连接..." : "获取链接"}
              </button>
            )}
            {errMsg ? "" : <p>{errMsg}</p>}
            <Link to="/login" style={{ textDecoration: "underline" }}>
              回上一页登录{" "}
            </Link>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
