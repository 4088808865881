import React from "react";
import { Link } from "react-router-dom";

export default function NavLinks() {
  return (
    <div className="NavLinks" style={{ margin: "0" }}>
      <div>
        <li className="navLink disapear">
          <Link to="/prpInfo">文书润色</Link>
        </li>
        <li className="navLink disapear">
          <Link to="/sopInfo">个人陈述撰写</Link>
        </li>
        <li className="navLink disapear">
          <Link to="/rcmInfo">推荐信撰写</Link>
        </li>
      </div>
    </div>
  );
}
