import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import UiHint from "../../components/UiHint";
import axios from "axios";
import Popup from "../../components/Popup";

//SOP_学习背景

const CNT_REGEX = /[\u4e00-\u9fa5]/;

export default function SopBckgrndForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const {
    docId,
    formCtn,
    setProgress,
    setFormCtn,
    titleChanged,
    typedTitle,
    allowAsisPopup,
    setAllowAsisPopup,
  } = useFormCtn();
  const [background, setBackground] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allowAsisPopup) {
      setTimeout(() => {
        //console.log("delay to showpopup");
        setPopupOpen(true);
        setAllowAsisPopup(false);
      }, 8000);
    }
  }, []);

  useEffect(() => {
    if (formCtn && formCtn[1]) {
      setBackground(formCtn[1]);
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[1]) {
        if (background !== formCtn[1]) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && !formCtn[1]) {
        setFormChanged(true);
        //console.log("new is typed");
      }
    };
    background && checkIfCtnChanged();
  }, [background]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [formCtn[0], background, formCtn[2], formCtn[3], formCtn[4]],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/2sopExprnForm/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };

    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/2sopExprnForm/${docId}`);
    }
    setFormChanged(false);
  };

  const navigateToAsistant = () => {
    window.open("/asistant", "_blank", "noreferrer");
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      {popupOpen && (
        <Popup
          buttonCtn={`asistant`}
          title={`💫免费体验小豆帮帮`}
          content={`帮您分析申请学校和专业特色，找出在个人陈述中可以强调的特点`}
          handleClose={() => {
            setPopupOpen(false);
          }}
          popupFunc={navigateToAsistant}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div className="rows">
          <div className="row">
            <dd className="sectitle">学习背景，相关课程经验</dd>
            <div className="textarea">
              <textarea
                type="text"
                value={background}
                onChange={(e) => {
                  const limit = 1000;
                  setBackground(e.target.value.slice(0, limit));
                }}
                required
                maxLength={1000}
              ></textarea>
              <div className="help">
                {background.length === 1000 ? (
                  <>
                    <span>1000 字 </span>
                    <span
                      style={{
                        color: "#DB5461",
                      }}
                    >
                      字数已达上限
                    </span>
                  </>
                ) : (
                  <span>1000 字</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="colorText" style={{ fontWeight: "600" }}>
              <span className="material-symbols-rounded">auto_fix</span>{" "}
              提示和示例
            </div>
            <ul>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">突出重点:</span>{" "}
                <span className="help">
                  在写学习背景和课程经验时，突出与申请学位相关的2～3门重点内容。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">显露热情:</span>{" "}
                <span className="help">
                  展示您对所学专业的热情和兴趣。您可以分享您为什么对这个领域感兴趣，以及您是如何对此领域进行深入学习的。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">结合实际: </span>{" "}
                <span className="help">
                  结合实际情况，给出具体的例子或经历。您可以描述您在课程中进行的实验项目或学术研究经历。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">突出成就: </span>{" "}
                <span className="help">
                  如果您曾获得过奖项或荣誉，或者您的学术研究或课程项目获得了好成绩，那么在陈述中要突出这些成就。
                </span>
              </li>
            </ul>
            <br />
            <div className="help">
              示例：
              <ul
                className="help"
                style={{ padding: "6px 0px 3px 0px", margin: "0px" }}
              >
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  修过多门信息科学课程，特别对机器学习和自然语言处理感兴趣
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  完成数据科学课程，学习了统计学，使用Python进行数据分析和可视化
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  课程中参与分析新浪微博数据集的项目，负责数据预处理和特征工程，并使用NLTK和jieba进行文本数据清洗和分词
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  选修高阶大数据分析课程，学习了处理TB级别的数据集以及分散式运算架构（如Hadoop和Spark）
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  投入大量时间在写作业和读论文上，从头写了MapReduce算法，对现代分散式运算架构有深度了解
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  期末作业在大数据集上使用了机器学习和神经网络，分析了不同超参数对训练速度和预测结果的影响，作业被评为最优秀之一
                </li>
              </ul>
            </div>
            <div className="help" style={{ marginTop: "10px" }}>
              ＊提醒您：如需提到专业术语，请直接用英语书写
            </div>
          </div>
        </div>
        <button
          className="float_btn"
          disabled={
            background && background && background && background ? false : true
          }
        >
          储存/下一步
        </button>
      </form>
      <UiHint
        content={`<span style="text-align:left;"><a href="/asistant" target="_blank" style="color:white;text-decoration:underline;font-weight: bold;">💫免费体验小豆帮帮💫</a>，总结在文书中可以强调的重点。</span>`}
      />
    </div>
  );
}
