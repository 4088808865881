import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import axios from "axios";

//SOP_毕业后的规划

const CNT_REGEX = /[\u4e00-\u9fa5]/;

export default function SopGraduForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const { formCtn, typedTitle, docId, setFormCtn, setProgress, titleChanged } =
    useFormCtn();
  const [graduate, setGraduate] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formCtn && formCtn[4]) {
      setGraduate(formCtn[4]);
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[4]) {
        if (graduate !== formCtn[4]) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && !formCtn[4]) {
        setFormChanged(true);
        //console.log("new is typed");
      }
    };
    graduate && checkIfCtnChanged();
  }, [graduate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [formCtn[0], formCtn[1], formCtn[2], formCtn[3], graduate],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/5sopPayment/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };

    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/5sopPayment/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div className="rows">
          <div className="row">
            <dd className="sectitle">毕业后的规划</dd>
            <div className="textarea">
              <textarea
                type="text"
                value={graduate}
                onChange={(e) => {
                  const limit = 500;
                  setGraduate(e.target.value.slice(0, limit));
                }}
                required
                maxLength={500}
              ></textarea>
              <div className="help">
                {graduate.length === 500 ? (
                  <>
                    <span>500 字 </span>
                    <span
                      style={{
                        color: "#DB5461",
                      }}
                    >
                      字数已达上限
                    </span>
                  </>
                ) : (
                  <span>500 字</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="colorText" style={{ fontWeight: "600" }}>
              <span className="material-symbols-rounded">auto_fix</span>{" "}
              提示和示例
            </div>
            <ul>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">明确职业目标:</span>{" "}
                <span className="help">
                  {" "}
                  表达自己在毕业后的职业规划和目标，说明希望在哪个行业或领域发展，包括继续深造读博、参与研究项目、获得实习经验、就业等。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">体现自我价值观和使命感： </span>{" "}
                <span className="help">
                  提及自己的价值观和使命感，表达对社会责任和影响力的关注，说明希望通过自己的工作和努力对社会做出积极的贡献。
                </span>
              </li>
            </ul>
            <br />
            <div className="help">
              示例：
              <ul
                className="help"
                style={{ padding: "6px 0px 3px 0px", margin: "0px" }}
              >
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我毕业后的规划是在信息科学领域中成为一名专业的数据科学家
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我对数据挖掘、机器学习和人工智能等领域充满兴趣
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我计划通过进一步深造和实践经验来提升自己的技能和专业知识
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我希望能够在一家领先的科技企业或研究机构工作，与跨学科的团队合作，解决实际问题并推动科技创新
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我也关注社会责任和可持续发展，希望通过自己的工作和努力对社会做出积极的贡献。
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          className="float_btn"
          disabled={graduate && graduate && graduate && graduate ? false : true}
        >
          储存/下一步
        </button>
      </form>
    </div>
  );
}
