import React, { useEffect, useState } from 'react'
import sanitizeHtml from "sanitize-html"
import ContentEditable from 'react-contenteditable'

const Editable = ({ writing, AIWriting, setEditedWriting }) => {

    const [HTMLcontent, setHTMLcontent] = useState("")

    useEffect(() => {
        //setContent(writing);

        setHTMLcontent("");
        const paragraphs = writing.split("\n\n");
        let paragraphsHTML = [];

        paragraphs.forEach(p => {
            const AIWriten = AIWriting.some(w => p.includes(w));
            let sentancesHTML = [];
            if (AIWriten) {
                let sentances = p.split(/(?<=[.!?])\s+/);
                sentances.forEach(s => {
                    const found = AIWriting.find((aiW) => aiW === s);
                    if (found) {
                        sentancesHTML.push("<strong>" + s + "</strong>");
                    } else {
                        sentancesHTML.push(s);
                    };
                })
                paragraphsHTML.push("<p>" + sentancesHTML.join("  ") + "</p>");
            } else {
                const newP = p.replace(/\n/g, "<br/>");
                paragraphsHTML.push("<p>" + newP + "</p>");
            };
        });
        setHTMLcontent(paragraphsHTML.join(""))


    }, [, writing])

    useEffect(() => {
        const extract = HTMLcontent.replace(/<p>/g, " ").replaceAll("</p>", "\n\n").replace(/<strong>/g, " ").replaceAll("</strong>", " ").replaceAll("<br/>", " \n ");
        setEditedWriting(extract);
    }, [HTMLcontent])

    const onContentChange = React.useCallback(evt => {
        const sanitizeConf = {
            allowedTags: ["br", "i", "a", "p", "strong"],
            allowedAttributes: { a: ["href"] }
        };

        setHTMLcontent(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf))
    }, [])
    /*
        useEffect(() => {
            setWriting(content);
        }, [content]);
    */

    return (
        <div>
            <ContentEditable
                style={{ padding: "10px 15px", border: "1px solid #CBD5E1", borderRadius: "4px" }}
                onChange={onContentChange}
                onBlur={onContentChange}
                html={HTMLcontent} />
        </div>
    );
};

export default Editable;
