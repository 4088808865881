import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import axios from "axios";

//推薦信_推薦信內容

export default function RcmCntForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const {
    docId,
    docTitle,
    formCtn,
    setFormCtn,
    setProgress,
    titleChanged,
    typedTitle,
  } = useFormCtn();
  const [relationship, setRelationship] = useState("");
  const [recommend, setRecommend] = useState("");
  const [general, setGeneral] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [count, setCount] = useState(0);

  console.log(titleChanged, typedTitle);

  useEffect(() => {
    if (formCtn && formCtn[2] && formCtn[2][0]) {
      setRelationship(formCtn[2][0]);
      setRecommend(formCtn[2][1]);
      setGeneral(formCtn[2][2]);
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[2] && formCtn[2].length === 3) {
        if (
          relationship !== formCtn[2][0] ||
          recommend !== formCtn[2][1] ||
          general !== formCtn[2][2]
        ) {
          setFormChanged(true);
          console.log("state is diferrent from formCtn");
          //console.log(relationship);
          //console.log(formCtn[2][0]);
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && formCtn[2] && formCtn[2].length < 3) {
        if (relationship || recommend || general) {
          setFormChanged(true);
          console.log("typed new");
        } else {
          setFormChanged(false);
        }
      }
    };

    relationship && recommend && general && checkIfCtnChanged();
  }, [relationship, recommend, general]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [formCtn[0], formCtn[1], [relationship, recommend, general]],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/3rcmPayment/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };
    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/3rcmPayment/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div>＊请以推荐人（老师）的角度填写。</div>
        <div className="rows">
          <div className="row">
            <div style={{ marginBottom: "20px" }}>
              <dd className="subtitle">推荐人和你的关系</dd>
              <textarea
                type="text"
                name="relationship"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
                required
                maxLength={150}
                style={{ height: "190px" }}
              ></textarea>
              <div className="help">
                {relationship && relationship?.length === 150 ? (
                  <>
                    <span>150 字 </span>
                    <span
                      style={{
                        color: "#DB5461",
                      }}
                    >
                      字数已达上限
                    </span>
                  </>
                ) : (
                  <span>150 字</span>
                )}
              </div>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <dd className="subtitle">希望推荐人如何推荐你</dd>
              <textarea
                type="text"
                name="recommend"
                value={recommend}
                onChange={(e) => setRecommend(e.target.value)}
                required
                maxLength={600}
                style={{ height: `${count * 1.3}px`, minHeight: "225px" }}
              ></textarea>
              <div className="help">
                {recommend && recommend?.length === 600 ? (
                  <>
                    <span>600 字 </span>
                    <span
                      style={{
                        color: "#DB5461",
                      }}
                    >
                      字数已达上限
                    </span>
                  </>
                ) : (
                  <span>600 字</span>
                )}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", minWidth: "100%" }}>
                <dd className="subtitle">推荐人的整体评价</dd>
                <textarea
                  type="text"
                  name="general"
                  value={general}
                  onChange={(e) => setGeneral(e.target.value)}
                  required
                  maxLength={150}
                  style={{ height: "180px" }}
                ></textarea>
                <div className="help">
                  {general && general?.length === 150 ? (
                    <>
                      <span>150 字 </span>
                      <span
                        style={{
                          color: "#DB5461",
                        }}
                      >
                        字数已达上限
                      </span>
                    </>
                  ) : (
                    <span>150 字</span>
                  )}
                </div>
              </div>
              <div
                className="help"
                style={{
                  position: "relative",
                  right: "-50px",
                  minWidth: "100%",
                  marginTop: "35px",
                }}
              >
                示例：李雷作为一个出色的学生，我相信他会在贵校取得出色的学业成就。{" "}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="help" style={{ marginTop: "33px" }}>
              示例：李雷是我计算机科学系的学生。在三年的教学工作中，他一直是个令人满意的学生。我在此推荐他申请斯坦福大学的信息科学专业。{" "}
            </div>
            <div className="help" style={{ marginTop: "240px" }}>
              示例：
              <ul
                className="help"
                style={{ padding: "6px 0px 3px 0px", margin: "0px" }}
              >
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  李雷是个勤奋好学的学生
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我曾是数据科学课的讲师，在我任课期间，李雷一直都能出色地完成所有的学习任务，并且常常提出自己的思考
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  李雷除了在课堂中有优秀的表现外，他还展示出对研究的热情
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  半年前，我曾指导他进行新浪微博舆情分析的项目，他认真钻研优化模型的方式，并能够清楚地展示出研究结果。在此期间，我看到他数据分析能力逐渐精进
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  李雷拥有出色的沟通和协调能力。他在分组讨论中，总是积极参与讨论并能够对组员的意见给予适当的反馈，他时常扮演推进讨论的角色，他的组员也一致给他极高的评价
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          className="float_btn"
          disabled={relationship && recommend && general ? false : true}
        >
          储存/下一步
        </button>
      </form>
    </div>
  );
}
