import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import axios from "axios";

//SOP_實習/工作经验

const CNT_REGEX = /[\u4e00-\u9fa5]/;

export default function SopExprnForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const { docId, formCtn, typedTitle, setFormCtn, setProgress, titleChanged } =
    useFormCtn();
  const [experience, setExperience] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formCtn && formCtn[2]) {
      setExperience(formCtn[2]);
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[2]) {
        if (experience !== formCtn[2]) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && !formCtn[2]) {
        setFormChanged(true);
        //console.log("new is typed");
      }
    };
    experience && checkIfCtnChanged();
  }, [experience]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [formCtn[0], formCtn[1], experience, formCtn[3], formCtn[4]],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/3sopCharctForm/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };

    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/3sopCharctForm/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div className="rows">
          <div className="row">
            <dd className="sectitle">相关课外活动/实习/工作 经验（补充</dd>
            <div className="textarea">
              <textarea
                type="text"
                value={experience}
                onChange={(e) => {
                  const limit = 500;
                  setExperience(e.target.value.slice(0, limit));
                }}
                required
                maxLength={500}
              ></textarea>
              <div className="help">
                {experience.length === 500 ? (
                  <>
                    <span>500 字 </span>
                    <span
                      style={{
                        color: "#DB5461",
                      }}
                    >
                      字数已达上限
                    </span>
                  </>
                ) : (
                  <span>500 字</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="colorText" style={{ fontWeight: "600" }}>
              <span className="material-symbols-rounded">auto_fix</span>{" "}
              提示和示例
            </div>
            <ul>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">具体的责任和成就:</span>{" "}
                <span className="help">
                  详细描述您在工作中承担的责任和取得的成就。强调您的职责范围、所获得的技能、解决问题的能力和取得的具体结果。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">得到的经验和教训: </span>{" "}
                <span className="help">
                  分享您从工作中获得的经验和教训。说明您如何应对挑战、成长和适应新环境，以及从中学到的重要教训。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">与个人发展和学术目标的关联: </span>{" "}
                <span className="help">
                  突出工作经验对您个人发展和学术目标的积极影响。说明您如何将这些经验与所申请的专业相结合，以及如何为自己的未来发展打下基础。
                </span>
              </li>
            </ul>
            <br />
            <div className="help">
              示例：
              <ul
                className="help"
                style={{ padding: "6px 0px 3px 0px", margin: "0px" }}
              >
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  大学期间担任电子工程学生会副主席，负责各类活动的组织和策划，如学术讲座、工程竞赛和实践项目
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  通过这些活动，提升了领导能力和团队合作精神，学会了压力下的时间和资源管理，深入了解了电子工程领域的挑战和机遇
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  在阿里巴巴完成暑期实习，加入软件开发团队，参与关键项目的开发和测试
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  实习期间学习了敏捷开发方法和团队协作的重要性，提升编程技能
                  (Python)，了解实际项目开发的流程和需求
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我的课外活动和实习经验丰富了学术背景，增强了实践能力，坚定自己选择电子工程作为专业的决心
                </li>
              </ul>
            </div>
            <div className="help" style={{ marginTop: "10px" }}>
              ＊提醒您：如需提到专业术语，请直接用英语书写
            </div>
          </div>
        </div>
        <button
          className="float_btn"
          disabled={
            experience && experience && experience && experience ? false : true
          }
        >
          储存/下一步
        </button>
      </form>
    </div>
  );
}
