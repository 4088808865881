import React, { useState } from "react";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import { useAuth, useMyWritings } from "../hooks/useContext";
import axios from "axios";

export default function Dropdown() {
  const { setAuth, auth } = useAuth();
  const { setLogIn, user } = useMyWritings();

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const handleWrtClick = () => {
    setClick(false);
  };

  //   const handleLogoutParse = () => {
  //     setClick(!click);
  //     const currentUser = Parse.User.current();
  //     if (currentUser) {
  //       // do stuff with the user
  //       console.log(currentUser);
  //       Parse.User.logOut();
  //       setLogIn(false);
  //     }
  //     setAuth({});
  //   };

  const handleLogout = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}api/v1/user/logout`,
        withCredentials: true,
      });
      if (res.data.status === "success") {
        console.log("success", "Logged out successfully!");
        //console.log(res.data);
        setAuth(false);
        setLogIn(false);
      }
    } catch (err) {
      console.log(err.response.data.message);
      alert("error", "Error logging out! Try again.");
    }
  };

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        <li className="user">{user}</li>

        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                state={item?.state}
                onClick={index === 0 ? handleWrtClick : () => setClick(false)}
              >
                <div className="hover">
                  <i className={item.iconClass}>{item.icon}</i>
                  {`\u00A0`} {item.title}
                </div>
              </Link>
            </li>
          );
        })}
        <li key={MenuItems.length + 1}>
          <Link className="logout" to="/" onClick={handleLogout}>
            <div className="hover">
              <i className="material-symbols-rounded">login</i>
              {`\u00A0\u00A0`}登出
            </div>
          </Link>
        </li>
      </ul>
    </>
  );
}
