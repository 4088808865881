import React from "react";
import catmeme from "../assets/catmeme.svg";

const Popup = ({ buttonCtn, handleClose, title, content, popupFunc }) => {
  const popUpBoxStyles = {
    position: "fixed",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
    justifyContent: "center",
    alignItems: "center",
  };
  const boxStyles = {
    width: "30vw",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "left",
    letterSpacing: "3px",
  };
  const titleStyles = {
    fontSize: "20px",
    marginBottom: "15px",
  };
  const buttonStyles = {
    width: "30%",
    marginLeft: "10px",
    backgroundColor: "white",
    color: "black",
    border: "solid 1px #CBD5E1",
    float: "right",
  };
  const fuctBtnStyles = {
    width: "auto",
    marginLeft: "10px",
    color: "white",
    backgroundColor: "black",
    border: "none",
    float: "right",
  };

  const buttonCtnMap = new Map([
    ["delete", "刪除文件"],
    ["asistant", "免费分析"],
  ]);

  return (
    <div>
      <div className="Popup" style={popUpBoxStyles} onClick={handleClose}>
        <div className="box" style={boxStyles}>
          <img src={catmeme} alt="catmeme" />
          <div style={titleStyles}>{title}</div>
          <div style={{ color: "#475569", paddingBottom: "20px" }}>
            {content}
          </div>

          <button onClick={popupFunc} style={fuctBtnStyles}>
            {buttonCtnMap.get(buttonCtn)}
          </button>
          <button onClick={handleClose} style={buttonStyles}>
            关闭
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
