import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/Logo.svg";
import free from "../assets/free.svg";
import chat from "../assets/chat.svg";
//import useAuth from '../hooks/useAuth';
import Dropdown from "./Dropdown";
import { useAuth } from "../hooks/useContext";
import AddChat from "../components/AddChat";

export default function Nav() {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [dropdown, setDropdown] = useState(false);
  const [addChatOpen, setAddChatOpen] = useState(false);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <div className="NavCover">
      <nav className="Nav">
        <Link to="/">
          <img alt="logo" className="logo" src={logo} />
        </Link>

        {auth ? (
          <div className="navLinks">
            <li className="navLink" style={{ border: "solid 1px #CBD5E1" }}>
              <Link to="/myWritings">
                <span className="material-symbols-rounded">note</span>
                {`\u00A0`}我的文书
              </Link>
            </li>

            <li className="navLink">
              <Link to="/asistant">
                <span className="material-symbols-outlined">smart_toy</span>
                {`\u00A0`}小豆帮帮
              </Link>
            </li>
            <img
              alt="free-university's-mayjor-analyzing"
              className="free"
              src={free}
              style={{ position: "relative", top: "-15px", left: "-35px" }}
            />

            <li
              className="navLink"
              style={{ padding: "30px 0", marginLeft: "-5px" }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={() => setDropdown(false)}
            >
              <Link to="/account">
                <span className="material-symbols-rounded">face</span>
                {`\u00A0`}
                <span>账户</span>
              </Link>
              {dropdown && <Dropdown />}
            </li>
            <li className="navLink">
              <Link
                onClick={() => {
                  setAddChatOpen(true);
                }}
              >
                <img
                  alt="consultant"
                  style={{
                    position: "relative",
                    top: "5px",
                    marginRight: "8px",
                  }}
                  src={chat}
                />
                <span>咨询客服</span>
              </Link>
            </li>
          </div>
        ) : (
          <div className="navLinks">
            <div className="navBtn">
              <button
                style={{
                  backgroundColor: "transparent",
                  borderStyle: "none",
                  color: "#2D3648",
                }}
                onClick={() => {
                  setAddChatOpen(true);
                }}
              >
                <img
                  alt="consultant"
                  style={{
                    position: "relative",
                    top: "5px",
                    marginRight: "8px",
                  }}
                  src={chat}
                />
                <span>咨询客服</span>
              </button>
            </div>
            <div className="navBtn">
              <button
                className="secondary_btn"
                onClick={() => {
                  navigate("/login");
                }}
              >
                登录
              </button>
            </div>
            <div className="navBtn">
              <button
                onClick={() => {
                  navigate("/register");
                }}
              >
                立即注册
              </button>
            </div>
          </div>
        )}
      </nav>
      {addChatOpen && (
        <AddChat
          handleClose={() => {
            setAddChatOpen(false);
          }}
        />
      )}
    </div>
  );
}
