import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./hooks/useContext";

export default function RequireAuth() {
  const { auth } = useAuth();

  return auth !== false || auth ? <Outlet /> : <Navigate to="/login" />;
}

//state={{ from: location }} replace
