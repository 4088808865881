import React, { useState } from 'react';

export default function UiHint({ content, icon }) {

    const [hint, setHint] = useState(true);

    const hintStyle = {
        position: 'fixed',
        display: 'flex',
        bottom: '0',
        width: 'auto',
        height: 'auto',
        padding: '14px 18px',
        backgroundColor: '#6B92F7',
        color: 'white',
        alignItems: 'center',
    }

    const htmlContent = content;


    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {hint && <div style={hintStyle}>
                <div style={{ flex: 'auto' }}>{icon}&nbsp;

                    <span dangerouslySetInnerHTML={
                        { __html: htmlContent }
                    } />

                </div>
                <div style={{ backgroundColor: '#6B92F7', flex: 'none', width: '30px' }} onClick={() => setHint(false)}><span className="material-symbols-rounded hover">close</span></div>
            </div>}
        </div>
    )
}
