import React from 'react';


const ProgressBar = ({ numerator, denominator }) => {

    const containerStyles = {
        height: 10,
        width: '100%',
        backgroundColor: "#E2E8F0",
        borderRadius: '0.2rem',
    }

    const fillerStyles = {
        height: '100%',
        width: denominator !== 0 ? `${(numerator / denominator) * 100}%` : `0%`,
        backgroundColor: '#6B92F7',
        borderRadius: '0.2rem 0.2rem 0.2rem 0.2rem',
    }

    return (
        <div className='ProgressBar'>
            <div style={{ fontSize: '14px', width: '100%', textAlign: 'right' }}>{numerator}/{denominator}</div>
            <div style={containerStyles}>{denominator === 0 && <span style={{ position: 'relative', top: '-9px', fontSize: '9px', color: 'gray', textAlign: 'center', marginLeft: '5px' }}>! 請重新整理</span>}
                <div style={fillerStyles}></div>
            </div>
        </div>
    )
};

export default ProgressBar;