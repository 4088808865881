import React, { useEffect, useState } from "react";
import Paraphrase from "./Paraphrase";
import { useNavigate, useLocation } from "react-router-dom";

export default function ErrParaphrase({
  docType,
  setErrMsgPrp,
  editedWriting,
  setEditedWriting,
  contentEdited,
}) {
  const popUpBoxStyles = {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
  };

  const boxStyles = {
    backgroundColor: "#FFFFFF",
    position: "fixed",
    top: "35vh",
    left: "35vw",
    width: "30vw",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "left",
    letterSpacing: "3px",
    border: "solid 1px lightgray",
    zIndex: "10",
    wordWrap: "break-word",
    borderRadius: "6px",
  };
  const buttonStyles = {
    border: "solid 1px #CBD5E1",
    borderRadius: "10em/10em",
    backgroundColor: "#FFFFFF",
    color: "#475569",
    float: "right",
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleCopy = async (e) => {
    const copyOperation = async function (text) {
      await navigator.clipboard.writeText(text);
      //console.log('Copy Success!')
      alert("文书已复制到剪切板");
    };

    setTimeout(async () => {
      if (!contentEdited) {
        console.log("from storage");
        copyOperation(localStorage.getItem("editedWriting"));
      } else if (editedWriting) {
        copyOperation(editedWriting);
      }
    }, 3000);
  };

  const AddBackQuota = () => {
    setErrMsgPrp("");
    navigate("/myWritings");
  };

  return (
    <div className="Popup" style={popUpBoxStyles}>
      <div className="box" style={boxStyles}>
        <div style={{ marginBottom: "20px" }}>
          {" "}
          🚧 连接失败，请先复制已编辑的文书，稍后再尝试一次。
        </div>
        <button onClick={AddBackQuota} style={buttonStyles}>
          关闭
        </button>
        <button
          style={{ float: "right", marginRight: "10px" }}
          onClick={handleCopy}
        >
          点我复制已编辑的文书
        </button>
      </div>
    </div>
  );
}
