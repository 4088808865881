export const RcmTabItems = [
    {
        title: '学生信息',
        path: '/0RcmBasicForm/',
        cName: 'rcmBasicForm'
    },
    {
        title: '推荐人资料',
        path: '/1RcmRcmdForm/',
        cName: 'rcmRcmdForm'
    },
    {
        title: '推荐信内容',
        path: '/2RcmCntForm/',
        cName: 'rcmCntForm'
    },
    {
        title: '付款',
        path: '/3RcmPayment/',
        cName: 'payment'
    },
];


export const SopTabItems = [
    {
        title: '学生信息',
        path: '/0SopBasicForm/',
        cName: 'SopBasicForm'
    },
    {
        title: '学习背景',
        path: '/1SopBckgrndForm/',
        cName: 'SopBckgrndForm'
    },
    {
        title: '实习/工作经验',
        path: '/2SopExprnForm/',
        cName: 'SopExprnForm'
    },
    {
        title: '学校专业特色',
        path: '/3SopCharctForm/',
        cName: 'SopCharctForm'
    },
    {
        title: '毕业后的规划',
        path: '/4SopGraduForm/',
        cName: 'SopGraduForm'
    },
    {
        title: '付款',
        path: '/5SopPayment/',
        cName: 'payment'
    },
];

export const PrpTabItems = [
    {
        title: `${`\u00A0\u00A0\u00A0\u00A0`}文书内容${`\u00A0\u00A0\u00A0\u00A0`}`,
        path: '/0PrpBasicForm/',
        cName: 'PrpForm'
    },
    {
        title: `${`\u00A0\u00A0\u00A0\u00A0`}付款${`\u00A0\u00A0\u00A0\u00A0`}`,
        path: '/1PrpPayment/',
        cName: 'payment'
    },
];