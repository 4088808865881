import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import UiHint from "../../components/UiHint";
import axios from "axios";

//SOP_学校专业特色

const CNT_REGEX = /[\u4e00-\u9fa5]/;

export default function SopCharctForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const { docId, formCtn, typedTitle, setFormCtn, setProgress, titleChanged } =
    useFormCtn();
  const [charaters, setCharaters] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formCtn && formCtn[3]) {
      setCharaters(formCtn[3]);
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[3]) {
        if (charaters !== formCtn[3]) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && !formCtn[3]) {
        setFormChanged(true);
        //console.log("new is typed");
      }
    };
    charaters && checkIfCtnChanged();
  }, [charaters]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [formCtn[0], formCtn[1], formCtn[2], charaters, formCtn[4]],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/4sopGraduForm/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };

    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/4sopGraduForm/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div className="rows">
          <div className="row">
            <dd className="sectitle">学校专业特色</dd>
            <div className="textarea">
              <textarea
                type="text"
                value={charaters}
                onChange={(e) => {
                  const limit = 750;
                  setCharaters(e.target.value.slice(0, limit));
                }}
                required
                maxLength={750}
              ></textarea>
              <div className="help">
                {charaters.length === 750 ? (
                  <>
                    <span>750 字 </span>
                    <span
                      style={{
                        color: "#DB5461",
                      }}
                    >
                      字数已达上限
                    </span>
                  </>
                ) : (
                  <span>750 字</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="colorText" style={{ fontWeight: "600" }}>
              <span className="material-symbols-rounded">auto_fix</span>{" "}
              提示和示例
            </div>
            <ul>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">学校特色:</span>{" "}
                <span className="help">
                  {" "}
                  多元文化的环境 / 学校的包容性和开放性 /
                  学校的历史悠久和丰富的学术传统
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">专业特色: </span>{" "}
                <span className="help">
                  该校该专业在行业中的知名度和优势 /
                  突出该专业拥有优秀的教师团队 /
                  描述该专业提供的实践机会和与行业合作的项目
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">个人兴趣和学习选课目标: </span>{" "}
                <span className="help">
                  明确说明您在该领域中最热衷的主题或方向，从申请专业的课程列表中选择想学习的课程，展示您对该专业的深入了解和专业兴趣。
                </span>
              </li>
              <li style={{ listStyleType: "disc" }}>
                <span className="suggest">其他项目和活动： </span>{" "}
                <span className="help">
                  提及想参加的比赛、发表论文/研讨会、创业等项目，展示您的积极主动和追求卓越的态度。
                </span>
              </li>
            </ul>
            <br />
            <div className="help">
              示例：
              <ul
                className="help"
                style={{ padding: "6px 0px 3px 0px", margin: "0px" }}
              >
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我对机器学习和自然语言处理有强烈兴趣，期望未来深入研究这个领域
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  贵校Information
                  Science专业在资讯领域颇负盛名，有许多机器学习领域的知名学者及实验室
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我很赞赏贵校多元包容的校园氛围和对国际学生的全面支持，这使得我感到被欣赏和受欢迎
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  贵校所在城市的科技公司密度高，贵校举办的大型求职说明会提供了丰富的就业机会
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  我已经有机器学习和自然语言处理的基础，希望在Stanford的Master’s
                  program in Information Science中能进一步深化这些知识。
                </li>
                <li style={{ listStyleType: "disc" }}>
                  我最感兴趣的是Advanced Natural Language
                  Processing课程和Designing Machine Learning
                  System课程，希望自己进一步提升自然语言处理和机器学习工程的知识，以及获得实际项目经验
                </li>
                <li style={{ listStyleType: "disc", marginBottom: "5px" }}>
                  贵校的创新孵化器拥有丰富的业界资金和人脉资源，对我具有强大的吸引力，期望能够利用这个资源来实现科技创业的人生规划
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          className="float_btn"
          disabled={
            charaters && charaters && charaters && charaters ? false : true
          }
        >
          储存/下一步
        </button>
      </form>
      <UiHint
        content={`<span style="text-align:left;"><a href="/asistant" target="_blank" style="color:white;text-decoration:underline;font-weight: bold;">💫免费体验小豆帮帮💫</a>，总结在文书中可以强调的重点。</span>`}
      />
    </div>
  );
}
