import React, { useEffect, useState } from "react";
import GenerateWriting from "./GenerateWriting";
import { useFormCtn, useMyWritings, useResult } from "../hooks/useContext";
import { useLocation, useParams } from "react-router-dom";
import { usePrice } from "../hooks/useContext";
import axios from "axios";

export default function Completion() {
  const popUpBoxStyles = {
    position: "fixed",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    left: "0",
    top: " 0",
    width: "100vw",
    height: "100vh",
    zIndex: "10",
    justifyContent: "center",
    alignItems: "center",
  };
  const boxStyles = {
    width: "30vw",
    backgroundColor: "#fff",
    padding: "20px 30px 30px 30px",
    overflow: "auto",
    textAlign: "left",
    letterSpacing: "3px",
  };
  const titleStyles = {
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: "15px",
    color: "#0F172A",
  };

  const location = useLocation();
  const { docNumber } = useParams();

  const [payedPrice, setPayedPrice] = useState();
  const [errMsg, setErrMsg] = useState("");

  const payment_intent = location.search.slice(
    location.search.indexOf("=") + 1,
    location.search.indexOf("=") + 28
  );

  useEffect(() => {
    const getPaymentDetail = async function () {
      try {
        const res = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}api/v1/payment/retrievePayment/${payment_intent}`,
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.paymentIntent);
          //console.log(res.data.paymentIntent.amount);
          setPayedPrice(res.data.paymentIntent.amount / 100);
        }
      } catch (error) {
        console.error("Error retrieving paymentdetail", error);
        setErrMsg("连线失败,请再试一次");
      }
    };
    getPaymentDetail();
  }, []);

  useEffect(() => {
    const savePayment = async function () {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}api/v1/payment/${docNumber}`,
          data: {
            intentId: payment_intent,
            amount: payedPrice,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data);
          console.log("payment saved");
        }
      } catch (error) {
        console.error("Error saving paymentInfo", error);
        setErrMsg("Error saving paymentInfo");
      }
    };

    //console.log(payedPrice);
    payedPrice && savePayment();
  }, [payedPrice]);

  return (
    <div>
      <div className="Popup" style={popUpBoxStyles}>
        <div className="box" style={boxStyles}>
          <div style={titleStyles}>🎉付款成功</div>
          <div style={{ color: "#475569" }}>
            请点击”生成文书”按钮，我们会立即为你生成英文文书！
          </div>
          <div style={{ float: "right", marginTop: "20px" }}>
            <GenerateWriting />
          </div>
        </div>
      </div>
    </div>
  );
}

/* 
<div style={{ display: 'flex' }}>
                <h3 className='title'>{docTitle}</h3>
            </div>
            <div style={{ margin: '170px 0px 30px 0px', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '400px', color: '#6B92F7' }}>
                    <h3>🎉🎉🎉 付款成功 🎉🎉🎉</h3>
                    <h3>请点击“提交潤色修改”按钮，我们会立即为您生成英文文书！</h3>
                </div>
            </div>
            <GenerateWriting />
*/
