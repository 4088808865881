import React from "react";
import notFound from "../assets/404.svg";

export default function ErrorPage() {
  return (
    <div>
      <img alt="not-Found-Page" src={notFound} style={{ marginTop: "100px" }} />
    </div>
  );
}
