import React from 'react'

export default function Skeleton() {

    const SkeletonWrapper = {
        position: 'relative',
    }

    const Skeleton = {
        width: '95%',
        height: '15px',
        background: '#ddd',
        overflow: 'hidden',
        margin: '10px 0',
        borderRadius: '4px',
    }

    const SkeletonShort = {
        width: '60%',
        height: '15px',
        background: '#ddd',
        overflow: 'hidden',
        margin: '10px 0',
        marginBottom: '30px',
        borderRadius: '4px',
    }

    return (
        <div style={SkeletonWrapper}>
            <div style={Skeleton}></div>
            <div style={Skeleton}></div>
            <div style={SkeletonShort}></div>
            <div style={Skeleton}></div>
            <div style={Skeleton}></div>
            <div style={SkeletonShort}></div>
            <div className="shimmer-wrapper">
                <div className="shimmer"></div>
            </div>
        </div>
    )
}
