import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormCtn, useMyWritings } from "../../hooks/useContext";
import ErrMessage from "../../components/ErrMessage";
import UiHint from "../../components/UiHint";
import axios from "axios";

//SOP_基本信息
export default function SopBasicForm() {
  const navigate = useNavigate();

  const { setRequireUpdate } = useMyWritings();
  const {
    docId,
    formCtn,
    docTitle,
    setProgress,
    setFormCtn,
    titleChanged,
    typedTitle,
  } = useFormCtn();

  const [current_uni, setCurrent_uni] = useState("");
  const [current_major, setCurrent_major] = useState("");
  const [program, setProgram] = useState("");
  const [university, setUniversity] = useState("");
  const [words, setWords] = useState(undefined);

  const [formChanged, setFormChanged] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (formCtn && formCtn[0] && formCtn[0][0]) {
      if (formCtn[0].length === 0) {
        //console.log("set form empty");
        setCurrent_uni("");
        setCurrent_major("");
        setProgram("");
        setUniversity("");
        setWords(undefined);
      } else {
        //console.log("set form from db");
        setCurrent_uni(formCtn[0][0]);
        setCurrent_major(formCtn[0][1]);
        setProgram(formCtn[0][2]);
        setUniversity(formCtn[0][3]);
        setWords(formCtn[0][4]);
      }
    }
  }, [formCtn]);

  useEffect(() => {
    const checkIfCtnChanged = () => {
      if (formCtn && formCtn[0] && formCtn[0].length === 5) {
        if (
          current_uni !== formCtn[0][0] ||
          current_major !== formCtn[0][1] ||
          program !== formCtn[0][2] ||
          university !== formCtn[0][3] ||
          words !== formCtn[0][4]
        ) {
          setFormChanged(true);
          //console.log("state is diferrent from formCtn");
        } else {
          setFormChanged(false);
        }
      } else if (formCtn && formCtn[0] && formCtn[0].length < 5) {
        if (current_uni || current_major || program || university || words) {
          setFormChanged(true);
          //console.log("typed new");
        } else {
          setFormChanged(false);
        }
      }
    };
    current_uni &&
      current_major &&
      program &&
      university &&
      words &&
      checkIfCtnChanged();
  }, [current_uni, current_major, program, university, words]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateDoc = async () => {
      try {
        const res = await axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_API_URL}api/v1/documents/${docId}`,
          data: {
            form: [
              [current_uni, current_major, program, university, words],
              formCtn[1],
              formCtn[2],
              formCtn[3],
              formCtn[4],
            ],
            docName: typedTitle,
          },
          withCredentials: true,
        });
        if (res.data.status === "success") {
          //console.log(res.data.data.doc);
          setProgress(res.data.data.doc.progress);
          setFormCtn(res.data.data.doc.form);
          setRequireUpdate(true);
          navigate(`/1sopBckgrndForm/${docId}`);
        }
      } catch (error) {
        console.error("Error while updating RcmForm", error);
        setErrMsg("更新文件遇到了一点小问题");
      }
    };

    if (formChanged || titleChanged) {
      updateDoc();
    } else {
      navigate(`/1sopBckgrndForm/${docId}`);
    }
    setFormChanged(false);
  };

  return (
    <div className="formStyle">
      {errMsg && (
        <ErrMessage
          message={errMsg}
          handleClose={() => {
            setErrMsg("");
          }}
        />
      )}
      <form autoComplete="on" onSubmit={handleSubmit}>
        <div>
          <dd className="subtitle">目前就读学校/工作单位（请填写英文名称）</dd>
          <input
            type="text"
            name="current_uni"
            value={current_uni}
            onChange={(e) => setCurrent_uni(e.target.value)}
            placeholder="示例：Tsinghua University/ Huawei Technologies Co., Ltd."
            required
          ></input>
        </div>

        <div>
          <dd className="subtitle">目前就读专业/职位（请填写英文名称）</dd>
          <input
            type="text"
            name="current_major"
            value={current_major}
            onChange={(e) => setCurrent_major(e.target.value)}
            placeholder="示例：Computer Science/ Data Engineer"
            required
          ></input>
        </div>

        <div>
          <dd className="subtitle">申请学位和专业（请填写英文名称）</dd>
          <input
            type="text"
            name="program"
            value={program}
            onChange={(e) => setProgram(e.target.value)}
            placeholder="示例：MSc in Information Science"
            required
          ></input>
        </div>

        <div>
          <dd className="subtitle">申请学校 （请填写英文名称）</dd>
          <input
            type="text"
            name="university"
            value={university}
            onChange={(e) => setUniversity(e.target.value)}
            placeholder="示例：Stanford University"
            required
          ></input>
        </div>

        <div style={{ width: "50%" }}>
          <div>
            <label htmlFor="words" style={{ width: "auto" }}>
              字数要求
            </label>
            <select
              type="number"
              name="words"
              value={words}
              onChange={(e) => setWords(e.target.value)}
              required
            >
              <option value={undefined}>选择字数要求上限</option>
              <option value={400}>400 英文单词 （大约1页）</option>
              <option value={500}>500 英文单词</option>
              <option value={600}>600 英文单词</option>
              <option value={700}>700 英文单词</option>
              <option value={800}>800 英文单词（大约2页）</option>
              <option value={900}>900 英文单词</option>
              <option value={1000}>1000 英文单词</option>
              <option value={1200}>1200 英文单词（大约3页）</option>
              <option value={1400}>1400 英文单词</option>
              <option value={1600}>1600 英文单词（大约4页）</option>
            </select>
          </div>
        </div>
        <button
          className="float_btn"
          disabled={
            current_uni && current_major && program && university && words
              ? false
              : true
          }
        >
          储存/下一步
        </button>
      </form>
      <UiHint
        content={"您可以在不同步骤之间切换并修改内容"}
        icon={<span className="material-symbols-rounded">auto_fix</span>}
      />
    </div>
  );
}
